// Icons

.icon {
	&.twitter {
		@include sprite($twitter);
		display: inline-block;
	}
	&.facebook {
		@include sprite($facebook);
		display: inline-block;
	}
	&.youtube {
		@include sprite($youtube);
		display: inline-block;
	}
	&.search {
		@include sprite($search);
		display: inline-block;
		position: relative;
		top: 3px;
	}
}