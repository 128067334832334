$museo-light:'Museo Sans W01_300';
$museo:'Museo Sans W01_500';
$museo-bold:'Museo Sans W01_700';
$meta:'Meta Offc W01 Normal';
$meta-bold:'MetaCompPro-Bold W01 Regular';
$calibri:'Calibri W01 Regular_904604';
$calibri-bold:'Calibri W01 Bold_904607';
$calibri-light:'Calibri W01 Light';



// Rem fallback mixin

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin font-size($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}


@mixin smooth {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin unsmooth {
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: auto;
}

h1, p, ul, ol, table {
	@include sp-m;	
}
h2, h3, h4 h5, h6 {
	@include sp;
}

@mixin heading-one {
	font-family: $museo;
	@include font-size(28px);
	@include sp-m;
	@media screen and (min-width:714px) {
		@include font-size(36px);
	}
	.homepage-header & {
		font-family: $museo-light;
	}
}

.heading-one {
	@include heading-one;
}

h1,
.h1 {
	@include heading-one;
	color: $navy;
}

@mixin heading-two {
	@include sp;
	font-family: $museo;
	@include font-size(30px);
}

.heading-two {
	@include heading-two;
}

h2,
.h2 {
	@include heading-two;
	@include sp;
	color: $navy;
}

@mixin heading-three {
	font-family: $museo;
	@include font-size(24px);
}

.heading-three {
	@include heading-three;
}

h3, h4, h5, h6 {
	@include heading-three;
	color: $navy;
	@include sp;
	&.underline {
		padding-bottom: 16px;
		padding-bottom: 1rem;
		@extend .hr;
		@include sp;
	}
	a {
		color: $navy;
	}
}
// h4 {
// 	display: block;
// 	font-size: 20px;
// 	color: $navy;
// 	line-height: 30px;
// 	margin-bottom: 10px;
// 	&.title {
// 		font-family: $museo-bold;
// 		font-size: 24px!important;
// 		color: $navy;
// 		line-height: 36px;
// 	}
// }

@mixin heading-min {
	font-family: $museo;
	font-size: 1em;
	border: none;
	@include sp;
	padding: 0;
}

.heading-min {
	@include heading-min;
}

hr, .hr {
	border: none;
	border-bottom: 2px solid #EEE;
	@include sp-m;
	&.invisible {
		visibility: hidden;
	}
	&.hr-thin {
		border-width: 1px;
	}
}

strong, b {
	font-family: $museo-bold;
}

p, li, th, td {
	@include font-size(16px);
	@include smooth;
	font-family: $museo-light;
}

@mixin list-reset {
	list-style-type: none;
}

@mixin list-inline {
	li {
		display: inline;
		margin-right: 0.4em;
	}
}


@mixin list-sidebar {
	li {
		@include sp-s;
	}
}


@mixin small {
	color: $grey;
	@include font-size(17px);
	@include smooth;
}

@mixin new-small {
	color: $grey;
	@include font-size(14px);
	@include smooth;
}

.breadcrumb {
	@include list-inline;
	color: #ffffff;
	@include sp;
	li {
		margin-right: 0;
	}
	li:last-child {
		&:after {
			display: none;
		}
	}
	li:after {
		content: ' › ';
	}
	a {
		color: #ffffff;
	}
}
ul{
	&#search-result-spec{
		li{
			h4 {
				margin-bottom:0;
			}
			.ex-sml{
				font-size:14px;
				margin:0;
				padding:0;
				color: $grey;
			}
		}
	}
}

table {
	thead {
		background-color:$navy;
		color: #ffffff;
		font-family: $museo;
	}
	th {
		text-align: left;
		font-family: $museo;
		color: #ffffff;
		background-color: $navy;
	}
	th,td {
		border-bottom: 1px solid $line;
		padding: 0.8em;
	}
	td {
		color: $black;
		font-family: $museo-light;
	}
}


// table {
// 	thead {
// 		background-color:$navy;
// 		color: #ffffff;

// 	}
// 	td {
// 		border-color:$new-border;
// 	}

// }
.councillor-party {
	@include font-size(18px);
	font-family: $museo;
	font-weight: normal;
	display: block;
	font-size: 18px;
	font-size: 1.125rem;
}
