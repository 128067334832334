@mixin list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

@mixin glow {
	background: #FFF;
	border: 5px solid $blue-light;
	border-radius: 3px;
}
.glow {
	@include glow;
}

//
//
// Forms
//
//
.bin_purchase_form {
	padding: 0em;
	background: #FFF;
	border: none!important;
	border-radius: 0px;
	margin-bottom: 0px;
	margin-bottom: 0em;
}
input[type=text],
input[type=email],
input[type=number],
input[type=tel],
input[type=search],
input[type=url],
textarea {
	font-family: $museo;
	@include font-size(15px);
	color: $navy;
	padding: 1em 1.2em;
	@include smooth;
	border:none;
	&:focus {
		outline: none;
	}
	.form-wrap & {
		border: none;
	}
	.form-row &, .unit & {
		// @include glow;
		padding: 1em;
		display: block;
		width: 100%;
		&:focus {
			border-color: darken($blue-light,26);
		}
	}
}

input[type=submit] {
	text-transform: uppercase;
		min-width:125px;
}

.ch-header {
	@media screen and (min-width:880px) {width:55%;}

}

.btn {
	font-family: $museo;
	@include font-size(14px);
	@media screen and (min-width:768px) {
		@include font-size(16px);
	}
	color: #FFF;
	background: $link;
	border: none;
	padding: 1em 1.2em;
	border-radius: 2px;
	cursor: pointer;
	border-radius: 56px;
	@include smooth;
	text-decoration: none;
	-webkit-appearance:none;
	&.btn-full {
		width: 100%;
		display: block;
		text-align: center;
	}
	&.btn-small {
		padding: 0.8em 1.2em;
	}
	&:hover {
		background: darken($link,6);
	}
	&:focus {
		background-color: #ffbf47;
		border-color: #ffbf47;
		outline: 3px solid #ffbf47;
	}
	.dark & {
		background: #fff;
		color: $link;
		border: 2px solid #fff;
		&:hover {
			color: #fff;
			background: none;
		}
	}
}
a[target=_blank] {
	&:after {
		@extend .icon;
		content: '';
		@include sprite($external-link);
		display: inline-block;
		margin-left: 0.1em;
		.downloads-list & {
			display: none;
		}
	}
}
span.btn {
	a[target=_blank] {
		color: #fff;
		text-decoration: none;
		&:after {
			@include sprite($external-link-w);
			display: inline-block;
			margin-left: 0.6em;
		}
	}
}

.form-wrap {
	@include glow;
	padding: 0.3em;
}


.autocomplete-suggestions {
	display: none;
	@media screen and (min-width:768px) {
		display: block;
		width: 100% !important;
		background: white;
		box-shadow: 0 3px 6px rgba(0,0,0,0.1);
		border-radius: 2px;
		// max-height: none !important;
		max-width: 370px;
		overflow: hidden;
		overflow-y: scroll;
		// max-width: 580px;
		margin-top: -25px;
		margin-left: -5px;
		padding-top: 25px;
	}
}
.autocomplete-suggestion {
	padding: 1em;
	color:#7F9CB6;
	border-bottom: solid 1px #E5EBF0;
	display: block;
	&:last-of-type {
		border-bottom:none;
	}
	&.autocomplete-selected {
		background: $blue-light;
		cursor: pointer;
		border-radius: 2px;
		color: $link;
	}
}

.bd-home {
	.autocomplete-suggestions {
		max-width: 580px!important;
		margin-left: 0px;
	}
}

.search-form {
	.form-wrap{
		width: 370px;
	    input[type=text],
	    input[type=search] {
		    text-align: left;
		    width:100%;
		    border-radius: 55px;
		    height: 50px;
		    padding: 5px 5px 5px 30px!important;
		}
	}
}




::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #7F9CB6;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #7F9CB6;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #7F9CB6;
}
:-moz-placeholder { /* Firefox 18- */
  color: #7F9CB6;
}

.getting-things-done.col-3 {
	max-width: none;
	ul {
		@media screen and (min-width:410px) {
			column-count: 2;
		}
		@media screen and (min-width:600px) {
			column-count: 3;
		}
	}
	@media screen and (min-width:$grid-full) {
		max-width: 33%;
		margin-top: -1.6em;
		ul {
			column-count: auto;
		}
	}
}
.upcoming-events.col-3 {
	@media screen and (min-width:$grid-full) {
		max-width: 33%;
	}
}

.pullout-box {
	// padding: 1.6em;
	// padding-right: 1.6em;
	@include sp-m;
	.bd-publications & {
		hr, p {
			@include sp;
		}
		.heading-min {
			@include sp-s;
		}
	}
	.heading-min {
		padding-top: 20px;
		@extend .heading-min;
	}
	ul {
		margin-bottom: 0;
		.bd-home & {
			@include sp;
		}
	}
	h1, h3, h4, h5, h6 {
		@include heading-three;
		@extend .hr;
		padding-bottom: 16px;
		@include sp;
	}

	h2 {
		@include heading-two;
		padding-bottom: 23px;
		@extend .hr;
		@include sp;
	}

	ul {
		@include list-reset;
		@include list-sidebar;
	}
}
.pullout-box {
    -webkit-animation:all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation:all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    animation:all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    
	// @media screen and (max-width:1045px) {
	// 	padding: 0 20px!important;
	// }
	@media screen and (min-width:1045px) {
		padding-top: 0.8em;
		padding-top: 1em;
	}
	&--header {
		background-color: #00386C;
		// background-color:  #00386c;$new-header-bg
		padding: 0px;
		border-bottom: solid 1px transparent;
		position: relative;
        &:before{
        	@media screen and (min-width:1045px) {
				content:'';
				display: block;
				width:12px;
				height: 12px;
				background:url(../images/icons/pullout-box-left-arrow.svg);
				background-repeat: no-repeat;
				position: absolute;
				left: 15px;
				top: 50%;
				margin-top:-6px;
			}
		}
		// &:after {
		// 	@media screen and (max-width:1045px) {
		// 		content:'';
		// 		// display: none;
		// 		width:12px;
		// 		height:15px;
		// 		background:url(../images/icons/current-page-arrow.svg);
		// 		background-size: auto;
		// 		background-position:-5px 0;
		// 		background-repeat: no-repeat;
		// 		position: absolute;
		// 		right: 20px;
		// 		top: 50%;
		// 		margin-top:-7px;
		// 	}
		// }
		.h2 {
			display: block;
			width: 100%;
			text-decoration: none;
			color: #FFFFFF!important;
			font-size: 1em;
			line-height: 24px;
			margin-bottom: 0px;
			margin-left: 0px!important;
			padding: 11px 30px;
			cursor: pointer;
			border:none;
			position: relative;

			&.mobile-hide {
				@media screen and (max-width:1045px) {
					display: none;
					visibility: hidden;
					padding: 0!important;
					margin: 0px;
				}
			}
			a {
				text-decoration: none!important;
				color: #FFFFFF!important;
			}
		}
		.current-page {
			display: none;
			position: relative;
			@media screen and (max-width:1045px) {
				background-color:$link;
				display: block;
				width: 100%;
				text-decoration: none;
				color: #FFFFFF!important;
				font-size: 18px;
				line-height: 1.1;
				margin-bottom: 0px;
				padding: 17px 20px;
				cursor: pointer;
				border:none;
				margin-top: -20px;
			}
			&:after {
				@media screen and (max-width:1045px) {
					content:'';
					// display: none;
					width:12px;
					height:15px;
					background:url(../images/icons/current-page-arrow.svg);
					background-size: auto;
					background-position:-5px 0;
					background-repeat: no-repeat;
					position: absolute;
					right: 20px;
					top: 50%;
					margin-top:-7px;
				}
			}
		}
	}
	&--inner {
		.sidebar-nav {
			display: block;
			li {
				margin: 0px;
				border-bottom: solid 1px $new-border;
				a {
					color: $navy;
					text-decoration: none;
					display: block;
					width: 100%;
					padding: 11px 20px;
					position: relative;
					cursor: pointer;
					font-size: 16px;
					font-size: 1em;
					font-family: $museo;
					@media screen and (min-width:768px) {
						padding: 11px 30px;
					}
					&:after {
						@media screen and (min-width:1045px) {
							content:'';
							display: block;
							width:12px;
							height: 12px;
							background:url(../images/icons/pullout-box-right-arrow.svg);
							background-repeat: no-repeat;
							position: absolute;
							right: 10px;
							top: 50%;
							margin-top:-6px;
						}
					}
					&:hover,
					&.current {
						background-color:#F2F8FC;
					}
					&.active {
						color:#ffffff;
						background-color: #007DC5;
					}
				}
				&.mobile-back {
					display: none;
					@media screen and (max-width:1045px) {
						background-color: $new-header-bg;
						display: block;
						a {
							color:#ffffff;
							padding-left:30px;
					        &:before{
								content:'';
								display: block;
								width:12px;
								height: 12px;
								background:url(../images/icons/pullout-box-left-arrow.svg);
								background-repeat: no-repeat;
								position: absolute;
								left: 15px;
								top: 50%;
								margin-top:-6px;
							}
							&:hover {
								background-color:$link;
							}
						}
					}
				}
				ul {
					display: none!important;
					li {
						margin: 0px;
						// padding-left:20px;
						border-bottom: solid 1px $new-border;
						&:first-of-type {
							border-top: solid 1px $new-border;
						}
						&:last-of-type {
							border-color: transparent;
						}
						&:hover,
						&.active,
						&.current {
							background-color:#F2F8FC;
						}
						a {
							color: $navy;
							text-decoration: none;
							display: block;
							width: 100%;
							padding: 11px 20px;
							position: relative;
							cursor: pointer;
							font-size: 14px;
							font-family: $museo;
							@media screen and (min-width:768px) {
								padding: 11px 30px;
							}
							&:after {
								@media screen and (min-width:1045px) {
									content:'';
									display: block;
									width:12px;
									height: 12px;
									background:url(../images/icons/pullout-box-right-arrow.svg);
									background-repeat: no-repeat;
									position: absolute;
									right: 10px;
									top: 50%;
									margin-top:-6px;
								}
							}
							&:hover,
							&.active,
							&.current {
								color: $navy!important;
								background-color:#F2F8FC;
							}
						}
						ul {
							li {
								// a { padding-left:20px; }
								ul {
									li {
										// a { padding-left:40px;}
									}
								}
							}
						}
					}
				}
				&.active {
					background-color: #F2F8FC;
					ul { 
						display: block!important;
						li {
							a:hover {
								text-decoration: underline;
							}
							ul {
								li {
									a { padding-left: 50px;}
								}
							}
						}
					}
				}
					
				
			}
		}
		@media screen and (max-width:1045px) {
			// padding: 0 20px;
		    // display: block;
		    // position: absolute;
		    // background: white;
		    // width: 100%;
		    // z-index: 99;
		    .heading-min {
		    	// padding-top: 20px;
		    }
		    .sidebar-nav {
		    	display: none;
		    }
		    // &.show-nav{
			   //  .sidebar-nav {
			   //  	display: block;
			   //  }
		    // }
		}
	}
}

.pullout-box {
	&.show-nav{
	    .sidebar-nav {
	    	display: block;
	    }
	}	
}	

.show-nav{
	.pullout-box--header {
		&:after {
			@media screen and (max-width:1045px) {
				content:'';
				background:url(../images/icons/current-page-arrow.svg);
				background-size: auto;
				background-position:0px -17px;
				width: 14px;
				height: 12px;
				top: 50%;
				margin-top:-7px;
			}
		}
	}
}

@media screen and (max-width:1045px) {
.pullout-box--header {
	&:after {
			content:'';
			// display: none;
			// visibility: hidden;
			background:url(../images/icons/current-page-arrow.svg);
			background-size: auto;
			background-position:0px -17px;
			width: 14px;
			height: 12px;
			top: 50%;
			margin-top:-7px;
		}
	}
}
.section-links {
	color: $grey;
	@include sp-m;
	h2, h3, h4, h5, h6 {
		@include heading-three;
		@include sp-s;
		a {
			color: $blue-dark;
			// text-decoration: none;
		}
	}
	> li {
		@include sp;
		@media screen and (min-width:$grid-start) {
			min-height: 9em;
		}
		position: relative;
		&:after {
			content: '';
			bottom: 0;
			left: 20px;
			right: 20px;
			position: absolute;
			@extend .hr;
			border-width: 1px;
			@include sp-0;
		}
	}
	ul {
		@include list-inline;
	}
	p, ul {
		@include sp;
	}
}

.form-row {
	@include sp;
}

.info-box {
	padding: 1.6em;
	background: $blue-light;
	border: 5px solid darken($blue-light,1);
	@include font-size(19px);
	overflow: hidden;
	border-radius: 3px;
	@include sp-m;
	p {
		margin-bottom: 0;
	}
}

.page-content {
	form {
		// padding: 1em;
		// @include glow;
		// @include sp-l;
		input[type=text],
		input[type=email],
		input[type=number],
		input[type=tel],
		input[type=search],
		input[type=url],
		textarea {
			width: 100%;
			border: 1px solid $line;
			color:$navy;
			&:focus {
				border-color: $link;
				box-shadow: 0 0 0 3px fade-out($link, 0.9);
			}
		}
		h2 {
			@include sp;
		}
		label {
			font-family: $museo-bold;
			@include font-size(16px);
			color: $navy;
			&.error {
				color: #dc143c !important;
			}
		}
		p {
			@include sp;
			color: $grey;
			&.form-mandatory {
				color: crimson;
				padding: 10px;
			}
		}
		input[type=submit] {
			@extend .btn;
			@include font-size(16px);
			padding: 1em 2em;
		}
	}
}

.error {
	color: rgb(192, 27, 27);
	font-family: $calibri !important;
	font-weight: normal;
	font-size: 16px !important;
	padding-top: 5px;
	display: block;
}

.blue-box {
	background-color:#F2F8FC;
	padding: 20px;
	color: $navy;
	line-height: 1.5;	
	@include sp-m;
	@media screen and (min-width:768px) {
		padding: 40px;
	}

	p {
		margin-bottom: 30px;
		color: #00386C;
		font-family: $museo;
		line-height: 1.5;	
		@media screen and (min-width:768px) {font-size: 20px;}
		&:last-of-type {
			margin-bottom: 0px;
		}
	}
}

.downloads-list {
	margin: 0;
}

.downloads-list--filesize {
	text-transform: uppercase;
	color: $grey;
}

.disable {
	opacity: 0.4;
	pointer-events: none;
}

.unit, .form-row {
	padding: 10px 10px 10px !important;
	@include sp-s;
	footer & {
		padding: 0 !important;
	}
	label {
		display: block;
		@include sp-s;
	}
	li label {
		display: inline;
	}
	select {
		width: 50%;
		display: block;
		font-size: 1.2em;
	}
}

.pagination {
	a, strong {
		display: inline-block;
		text-decoration: none;
		background: #fff;
		padding: 0.2em 0.7em;
		border: 1px solid $line;
	}
	strong {
		background: none;
		font-family: $calibri;
	}
	a {
		&:hover {
			background: $link;
			color: #fff;
			border-color: $link;
		}
	}
}

.list-atoz {
	list-style-type: none;
	// @include sp-xl;
	margin-bottom:40px;
	text-align: center;
	@media screen and (min-width:726px) {
		text-align: left;
	}
	@media screen and (max-width:1045px) {
		padding-top:20px;
	}
	li {
		display: inline;
		span, a {
			padding: 0.2em 0.65em;
			display: inline-block;
			border: 1px solid $line;
			color: $grey;
			margin-right: 0.3em;
			margin-bottom: 0.3em;
		}
		a {
			color: #00386C;
			// background: #7F9CB6;
			text-decoration: none;
			border-color: #7F9CB6 !important;
			&:hover {
				background: #7F9CB6;
				color: #fff;
			}
		}
	}
}

.list-councillors {
	@include sp-xl;
	h3 {
		@include sp-xs;
	}
}
.list-councillors--councillor {
	@include sp-m;
	img {
		@include glow;
	}
}

.sitewide-bar {
	background: #fff;
	color: #846123;
	padding: 20px 20px 0;
	padding: 1rem 1rem 0;
	overflow: hidden;
	text-align: center;
	h1, h2, h3, h4, h5, h6, p {
		@include sp;
	}
	&.red {
		background: #e8bcba;
		color: #a41e20;
	}
	&.green {
		background: #d7f2cd;
		color: #496041;
	}
	&.blue {
		background: #cae4f2;
		color: #4070a5;
	}
	&.transparent {
		background: #ffffff;
		color: #00386c;
	}
}

.ff_composer {
	.required_item {
	  color: #dc143c !important;
	}
	fieldset {
	  // border: 1px solid rgb(234, 234, 234);
	  // padding: 10px;
	// @include sp;
	margin-bottom:30px;
	}
}

fieldset {
	h4 {
		padding: 10px;
	}
}

.sample {
	color: $grey;
	display: block;
	padding-top: 3px;
}

.unit {
	&.postcode-inline {
		select {
			width: auto;
			display: inline;
		}
		#txtPostcode2 {
			display: inline;
			background: #FFF;
			border: 1px solid #dedede;
			border-radius: 3px;
			padding: 0.5em 0.8em;
			font-family: 'Museo Sans W01_500';
			font-size: 15px;
			font-size: .9375rem;
		}
	}
}

.bttn {
	font-family: $museo;
	@include font-size(16px);
	border: solid 1px;
	padding: 1em 1.2em;
	border-radius: 56px;
	cursor: pointer;
	@include smooth;
	text-decoration: none;
	text-align: center;
	-webkit-appearance:none;
	display:inline-block!important;
	text-decoration:none;
	&--signup {
		border-color: $link;
		color: #FFF;
		background: $link;
		margin: 0 auto;
		padding: 15px 45px;
		text-transform: uppercase;
	}
	&--primary {
		border-color: $link;
		color: #FFF;
		background: $link;
		margin: 0 auto;
		padding: 15px 45px;
		padding-left: 30px;
		text-decoration: none;
		&:after {
			content:'';
			display: block;
			width:12px;
			height: 15px;
			background:url(../images/icons/cta-arrow.svg);
			background-repeat: no-repeat;
			position: absolute;
			right: 20px;
			top: 50%;
			margin-top:-7px;
		}
		&:hover {
			// background: transparent;
			// color: $link;
			text-decoration:none!important;
			background: darken($link,6);
			// &:after {
			// 	content:'';
			// 	display: block;
			// 	background-position:0 -15px;
			// }
		}

	}
	&--secondary {
		border-color: #FFF;
		color: $link;
		background: #FFF;
	}
	&--ghost {
		border-color: #7F9CB6;
		color: #7F9CB6;
		background: transparent;
		min-width: 160px;
		&:after {
			content:'';
			display: block;
			width:12px;
			height: 15px;
			background:url(../images/icons/ghost-btn-arrow.svg);
			background-repeat: no-repeat;
			position: absolute;
			right: 20px;
			top: 50%;
			margin-top:-7px;
		}
	}
	&--view-more {
		border-color: $link;
		color: #FFF;
		background: $link;
		margin: 0 auto;
		padding: 15px 45px;
		&:after {
			content:'';
			display: block;
			width:10px;
			height: 10px;
			background:url(../images/icons/more-services.svg);
			background-repeat: no-repeat;
			position: absolute;
			right: 25px;
			top: 50%;
			margin-top:-5px;
		}
		&:hover {
			&:after {
				content:'';
				background:url(../images/icons/more-services-blue.svg);
			}
		}
	}
}

.js-view-more,
.js-view-less {
	font-family: $museo;
	@include font-size(16px);
	border: solid 1px;
	padding: 1em 1.2em;
	border-radius: 56px;
	cursor: pointer;
	@include smooth;
	text-decoration: none;
	text-align: center;
	-webkit-appearance:none;
	display:inline-block!important;
	text-decoration:none;
	border-color: $link;
	color: #FFF;
	background: $link;
	margin: 0 auto;
	padding: 15px 45px;
	&:after {
		content:'';
		display: block;
		width:10px;
		height: 10px;
		background:url(../images/icons/more-services.svg);
		background-repeat: no-repeat;
		position: absolute;
		right: 25px;
		top: 50%;
		margin-top:-5px;
	}
	&:hover {
		background: darken($link,6);
	}
}
.js-view-less {
	&:after {
		content:'';
		display: block;
		width:10px;
		height: 2px;
		background:url(../images/icons/view-less.png);
		background-repeat: no-repeat;
		position: absolute;
		right: 25px;
		top: 50%;
		margin-top:-1px;
	}
}
