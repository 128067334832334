// $link: #3E75A3;
$link: #007DC5;
// $black: #171717;
$black: #333333;
// $grey: #979797; - original
$grey: #7F9CB6; 
$blue: #3E75A3; // Same as $link
// $blue-dark: #0F4282;
$blue-dark: #00386C;
$blue-darkest: #110660;
$blue-light: rgba(199,223,243,0.23);
$navy: #00386C; //new link color - AUG 2016
$new-blue: #007DC5; //new link color - AUG 2016
$line: #CCD8E2;
$new-header-bg: #7F9CB6; // blue color
$new-border: #CCD8E2;
$nav-full: 1278px; // was 1322px
$grid-start: 600px;
$grid-full: 1050px;
$secondary-bg:#F2F8FC;

@mixin link-reset {
	position: static;
	text-shadow: none;
	&:after {
		display: none;
	}
}