:root {
    --cky-font-base:             "Museo Sans W01_300";
    --cky-font-bold:             "Museo Sans W01_500";

    --cky-title-font:            "Museo Sans W01_700";
    --cky-title-size:            24px;
    --cky-title-size-mob:        20px;

    --cky-paragraph-size:        15px;
    --cky-paragraph-lh:          24px;

    --cky-padding-box-mob:       30px 0;
    --cky-padding-box:           30px;

    --cky-border-radius-box:     2px;
    --cky-border-radius-button:  5px;

    --cky-brand-primary:         #00396C;
    --cky-brand-background:      #FFF;
    --cky-brand-heading:         #00396C;
    --cky-brand-text:            #000;
    --cky-brand-link:            var(--cky-brand-primary);
    --cky-brand-success:         var(--cky-brand-primary);
    --cky-brand-warning:         #999999;

    --cky-revisit-bg:            var(--cky-brand-primary);

    --cky-btn-weight:            bold;

    --cky-btn-accept-bg:         var(--cky-brand-primary);
    --cky-btn-reject-bg:         #FFF;
    --cky-btn-customize-bg:      #FFF;

    --cky-btn-accept-border:     2px solid var(--cky-brand-primary);
    --cky-btn-reject-border:     2px solid var(--cky-brand-primary);
    --cky-btn-customize-border:  2px solid var(--cky-brand-primary);
    --cky-btn-pref-border:       2px solid var(--cky-brand-primary);

    --cky-btn-accept-color:      #FFF;
    --cky-btn-reject-color:      var(--cky-brand-primary);
    --cky-btn-customize-color:   var(--cky-brand-primary);
    --cky-btn-pref-color:        var(--cky-brand-primary);

    --cky-checkbox-bg:           #000;
    --cky-checkbox-checked-bg:   var(--cky-brand-primary);
    --cky-checkbox-border:       1px solid #000;


    --cky-table-th-bg:           var(--cky-brand-primary);

    --cky-table-th-padding:      10px;
    --cky-table-td-padding:      10px;

    --cky-table-th-border:       none;
    --cky-table-td-border:       none;

    --cky-table-th-size:         16px;
    --cky-table-td-size:         16px;

    --cky-table-th-color:        #FFF;
    --cky-table-td-color:        var(--cky-brand-text);

    --cky-table-bg-odd:          #F3F3F3;
    --cky-table-bg-even:         #FFF;
}

//
//  Cookie Banner
//  _____________________________________________

.cky-btn-revisit-wrapper {
    background-color: var(--cky-revisit-bg) !important;
}

.cky-consent-bar {
    padding: var(--cky-padding-box) !important;
    background-color: var(--cky-brand-background) !important;
    border-radius: var(--cky-border-radius-box) !important;
    border-color: var(--cky-brand-background) !important;

    @media (max-width: 440px) {
        padding: var(--cky-padding-box-mob) !important;
    }
}

.cky-title {
    font-size: var(--cky-title-size) !important;
    font-family: var(--cky-title-font) !important;
    color: var(--cky-brand-heading) !important;

    @media (max-width: 440px) {
        font-size: var(--cky-title-size-mob) !important;
    }
}

.cky-notice-des p {
    line-height: var(--cky-paragraph-lh);
    color: var(--cky-brand-text) !important;
    font-size: var(--cky-paragraph-size);
}

.cky-notice-des p a {
    font-family: var(--cky-font-bold) !important;
    color: var(--cky-brand-link) !important;
    font-weight: var(--cky-btn-weight) !important;
}

.cky-notice-btn-wrapper {
    @media (max-width: 440px) {
        flex-direction: row !important;
    }
}

.cky-btn {
    font-family: var(--cky-font-bold) !important;
    border-radius: var(--cky-border-radius-button) !important;
    font-weight: bold !important;
}

.cky-btn-accept {
    background-color: var(--cky-btn-accept-bg) !important;
    border: var(--cky-btn-accept-border) !important;
    color: var(--cky-btn-accept-color) !important;
}

.cky-btn-reject {
    background-color: var(--cky-btn-reject-bg) !important;
    border: var(--cky-btn-reject-border) !important;
    color: var(--cky-btn-reject-color) !important;

    @media (max-width: 440px) {
        flex: 0 1 calc(50% - 5px) !important;
        margin-left: 5px !important;
    }
}

.cky-btn-customize {
    background-color: var(--cky-btn-customize-bg) !important;
    border: var(--cky-btn-customize-border) !important;
    color: var(--cky-btn-customize-color) !important;

    @media (max-width: 440px) {
        flex: 0 1 calc(50% - 5px) !important;
        margin-right: 5px !important;
    }
}

.cky-btn-preferences {
    border: var(--cky-btn-pref-border) !important;
    color: var(--cky-btn-pref-color) !important;
}

//
//  Cookie Preference Centre
//  _____________________________________________

.cky-preference-center {
    background-color: var(--cky-brand-background) !important;
    border-color: var(--cky-brand-text) !important;
    border-radius: var(--cky-border-radius-box) !important;
}

.cky-footer-shadow {
    display: none !important;
}

.cky-preference-title {
    color: var(--cky-brand-heading) !important;
}

.cky-preference-content-wrapper {
    color: var(--cky-brand-text) !important;
}

.cky-show-desc-btn {
    color: var(--cky-brand-link) !important;
    text-decoration: underline !important;
}

.cky-always-active {
    color: var(--cky-brand-success) !important;
}

.cky-accordion-btn {
    color: var(--cky-brand-heading) !important;
}

.cky-accordion-header-des {
    color: var(--cky-brand-text) !important;
}

.cky-chevron-right {
    color: var(--cky-brand-link) !important;
}

.cky-audit-table {
    background-color: var(--cky-brand-background) !important;
}

.cky-cookie-des-table {
    color: var(--cky-brand-text);
}

.cky-switch input[type="checkbox"] {
    background-color: var(--cky-checkbox-bg) !important;
    border: var(--cky-checkbox-border) !important;
}

.cky-switch input[type="checkbox"] {
    border: 1px solid var(--cky-brand-text) !important;
}

.cky-switch input[type="checkbox"]::before {
    transform: translateX(-1px) translateY(1px) !important;
}

.cky-switch input[type="checkbox"]:checked {
    background-color: var(--cky-checkbox-checked-bg) !important;
    border-color: var(--cky-checkbox-checked-bg) !important;
}

.cky-switch input[type="checkbox"]:checked::before {
    transform: translateX(19px) translateY(1px) !important;
}

[data-cky-tag="detail-powered-by"] {
    background-color: transparent !important;
}

//
//  Cookie Table
//  _____________________________________________

.cky-cookie-audit-table th {
    padding: var(--cky-table-th-padding) !important;
    font-size: var(--cky-table-th-size) !important;
    background-color: var(--cky-table-th-bg) !important;
    color: var(--cky-table-th-color) !important;
    border: var(--cky-table-th-border) !important;
}

.cky-cookie-audit-table td {
    padding: var(--cky-table-td-padding) !important;
    font-size: var(--cky-table-td-size) !important;
    color: var(--cky-table-td-color) !important;
    border: var(--cky-table-td-border) !important;
}

.cky-cookie-audit-table tr:nth-child(2n + 1) td {
    background-color: var(--cky-table-bg-odd) !important;
}

.cky-cookie-audit-table tr:nth-child(2n) td {
    background-color: var(--cky-table-bg-even) !important;
}