@mixin dib {
	display: inline-block;
	vertical-align: top;
}

@mixin sp-0 {
	margin-bottom: 0;
}

@mixin sp-xs {
	margin-bottom: 5px;
	margin-bottom: 0.31rem;
}

@mixin sp-s {
	margin-bottom: 10px;
	margin-bottom: 0.63rem;
}

@mixin sp {
	margin-bottom: 20px;
	margin-bottom: 1.25rem;
}

@mixin sp-m {
	margin-bottom: 30px;
	margin-bottom: 1.875rem;
}

@mixin sp-l {
	margin-bottom: 60px;
	margin-bottom: 3.75rem;
}

@mixin sp-xl {
	margin-bottom: 75px;
	margin-bottom: 4.69rem;
}

@mixin sp-xxl {
	margin-bottom: 120px;
	margin-bottom: 7.5rem;
}

.sp-0 { @include sp-0; }
.sp-xs { @include sp-xs; }
.sp-s { @include sp-s; }
.sp { @include sp; }
.sp-m { @include sp-m; }
.sp-l { @include sp-l; }
.sp-xl { @include sp-xl; }
.sp-xxl { @include sp-xxl; }

.container {
	// max-width: 1359px;
	// max-width: 1200px;
	margin: 0 auto;
	width: 100%;
	padding: 0px;
	//padding: 0 40px;
}

//
//
// The Grid
// Usage: All grid items must be placed in a .grid-row
//
//

.grid-row {
	margin-left: 0px;
	margin-right: 0px;
	@media screen and (min-width:$grid-start) {
		// margin-left: -15px;
		// margin-right: -15px;
		margin-left: -20px;
		margin-right: -20px;
	}
}

// Two columns

.col-2 {
	@include dib;
	width: 100%;
	@media screen and (min-width:$grid-start) {
		max-width: 50%;
		padding: 0 20px;
	}
}


// Three columns

.col-3 {
	@include dib;
	width: 100%;
	@media screen and (min-width:$grid-start) {
		max-width: 50%;
		padding: 0 20px;
	}
	@media screen and (min-width:$grid-full) {
		max-width: 33%;
	}
}

// Two Thirds

.col-2-3 {
	@include dib;
	width: 100%;
	padding: 0 20px;
	@media screen and (min-width:$grid-full) {
		max-width: 66%;
	}
}

// Four columns

.col-4 {
	@include dib;
	width: 100%;
	@media screen and (min-width:$grid-start) {
		max-width: 50%;
		padding: 0 20px;
	}
	@media screen and (min-width:$grid-full) {
		max-width: 25%;
	}
}
