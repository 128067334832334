$menu: -2px -2px 14px 10px;
$external-link-w: -2px -16px 13px 13px;
$external-link: -2px -33px 13px 13px;
$search: -2px -50px 16px 16px;
$twitter: -2px -70px 24px 19px;
$youtube: -2px -93px 24px 19px;
$facebook: -2px -116px 21px 21px;
// $logo-small: -2px -141px 177px 67px;
// $logo: -2px -212px 225px 85px;
$logo-small: 0px -100px 151px 57px;
$logo: 0px 0px 200px 79px;

@mixin sprite-width($sprite) {
  width: nth($sprite, 3);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 4);
}

@function sprite-width($sprite) {
  @return nth($sprite, 3);
}

@function sprite-height($sprite) {
  @return nth($sprite, 4);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 1);
  $sprite-offset-y: nth($sprite, 2);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite($sprite) {
  @include sprite-position($sprite);
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

.icon {
  background-image: url('../../images/icons/sprite.png');
}
.icon-logo {
  background-image: url('../../images/icons/new-mea-logo.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  &.logo-small {
    background-position: 0px -80px;
    width: 151px;
    height: 65px;
    display: inline-block;
     @media screen and (min-width:874px) {
       display: none;
     }
  }
  &.logo {
    background-position: 0px 0px;
    width: 200px;
    height: 75px;
    display: none;
    @media screen and (min-width:874px) {
       display: inline-block;
    }
  }
}
// .icon-logo {
//   background-image: url('../../images/icons/new-mea-logo.png');
// }

@media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .icon {
    background-image: url('../../images/icons/sprite@2x.png');
    background-size: 229px 299px;
  }
  .icon-logo {
    background-image: url('../../images/icons/new-mea-logo.svg');
    background-size: 100%;
  }
}

.icon.menu, a.menu[target=_blank]:after{
  background-position:-2px -2px;
  background-repeat:no-repeat;
  overflow:hidden;
  display:block;
  width:14px;
  height:10px;
  float:right;
}


// NEW STYLES AUG 2016
.ui_icon {
  background-image: url('../images/icons/new-sprite.svg');
  background-size: auto;
  background-repeat:no-repeat;
  display: block;
  &.social {
    width:35px;
    height:35px;
    &.twitter {
      background-position: 0px -105px;
    }
    &.facebook {
      background-position: -45px -105px;
    }
    &.instagram {
      background-position: -90px -105px;
    }
  }
  // &.pay-icons {
  //   background-image: url('../images/icons/new-sprite.png');
  //   background-position: 0 -153px;
  //   width: 650px;
  //   height: 50px;
  // }
}

// @media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
//   .ui_icon {
//     background-image: url('../images/icons/new-sprite.png');
//   }
// }

