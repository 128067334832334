header {
	padding: 1em 20px;
	background: #fff;
	.container {position: relative;}
}

.logo, .logo-small {
	vertical-align: middle;
	@include link-reset;
	margin-right: 2em;
	// @include sprite($logo);
	display: inline-block;
	display: none;
	span {
		display: none;
	}
}

// .logo-small {
// 	@include sprite($logo-small);
// 	margin-right: 0;
// 	position: relative;
// 	footer & {
// 		@include sp;
// 	}
// 	@media screen and (min-width:702px) {
// 		margin-right: 5em;
// 	}
// 	@media screen and (min-width:874px) {
// 		display: none;
// 		footer & {
// 			display: inline-block;
// 		}
// 	}
// }
// .logo-large {
// 	@media screen and (min-width:874px) {
// 		display: inline-block;
// 	}	
// }

.form-search {
	text-align: left;
	width: 100%;
	max-width: 460px;
	label {
		display: none;
	}
	input {
		display: inline-block;
	}
	input[type=text] {
		width: 63%;
		@media screen and (min-width:450px) {
			width: 78%;
		}
	}
	.btn {
		float: right;
	}
}
#mobile-menu {
	form.form-search {
		width: 100%!important;
		max-width: 100%!important;
	}
	input[type=text] {
		width: 100%!important;
	}
}
.header-left, .header-middle, .header-right {
	display: inline-block;
	vertical-align: middle;
}

.header-middle {
	width: 100%;
	clear: both;
	margin-top: 1.6em;
	display: none;
	form {
		margin: 0 auto;
		@media screen and (min-width:702px) {
			margin: 0;
		}
	}
	@media screen and (min-width:702px) {
		max-width: 403px;
		clear: none;
		margin-top: 0;
		display: inline-block !important;
	}
	.btn-search {
		float: right;
	}
	@media screen and (min-width:990px) {
		clear: none;
		max-width: 495px;
		max-width: 475px;
	}
}

.header-right {
	@media screen and (max-width:990px) {
		display:block;
		margin-top: 1em;
		position: absolute;
		left: -20px;
		right:-20px;
		z-index:999999;
	}
	@media screen and (min-width:990px) {
		margin-top: 0.9em;
		display: inline-block;
		text-align: right;
		float: right;
		position: relative;
	}
	// @media screen and (min-width:880px) and (max-width:1024px){
	// 	padding-right: 60px;
	// }
	ul {
		li {
			display: block;
			border-bottom: solid 1px #406990;
		}
		@media screen and (min-width:990px) {
			@include list-sidebar;
			@include list-inline;
			margin-bottom: 0;
			li {
				margin-bottom: 0;
				margin-right: 0;
				a {
					color: $navy;
					// margin-right: 0.3em;
					&:hover {
						color:$link;
					}
				}
				&:last-child {
					a {
						margin-right: 0;
					}
				}
				&.utility-nav--social a {
					margin-left: 0.3em;
				}
			}
		}
	}
}

.list-social {
	margin-bottom: 0;
	a:after {
		border: none;
	}
}

.nav-main-wrap {
	display: block;
	position: absolute;
	top: 0px;
	right: 20px;
	a {
		text-decoration: none;
	}
	ul {
		margin-bottom: 0;
	}
}

.nav-main {
	display: block;
	@include font-size(19px);
	ul {
		display: block;
	}
	li {
		display: inline-block;
		font-family: $museo;
		@include font-size(18px);
		@include smooth;
		a {
			display: inline-block;
			color: $navy;
			padding: 0.9em 0.8em;
			@include link-reset;
			text-decoration: none;
			&:hover {
				// background: $link;
			}
		}
	}
	.nav-main--tasks li a {
		padding: 0.9em 0.6em;
	}
}

.nav-main--tasks {
	background: $blue-darkest;
	float: right;
}

.utility-nav, .list-social, .list-contact {
	li {
		display: inline;
	}
}

.utility-nav {
	margin-bottom: 8px;
}

.icon.menu {
	@include sprite($menu);
	float: right;
}

.nav-main__large {
	display: none;
	@media screen and (min-width:$nav-full) {
		display: block;
		overflow: hidden;
	}
	.first {
		display: none;
		@media screen and (min-width:1310px) {
			display: inline;
		}
	}
}

.nav-main__small {
	position: relative;
	@media screen and (min-width:$nav-full) {
		display: none;
	}
}
.nav-main__small-menu {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
}
.nav-main__small-menu--icon {
	background: $blue-darkest;
	padding: 1.28em;
	overflow: hidden;
	float: right;
}
.nav-main__small-menu--links {
	z-index: 2;
	background: $blue-darkest;
	width: 10em;
	clear: both;
	display: none;
	&.active {
		display: block;
	}
	a:hover {
		background: none !important;
	}
}

.nav-main__small--wrap {
	overflow-x: auto;
	//white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	padding-right: 4em;
}


.nav-main__small-menu--links {
	li {
		display: block;
	}
}

.btn-search {
	float: right;
	margin-top: 10px;
	@media screen and (min-width:990px) {
		display: none;
	}
}

.js-skip-links {
    display: block;
    text-indent: -999999px;
    bottom: 0;
    right: 20px;
    position: absolute;
	width:40px;
	height:40px;

	@media screen and (min-width:990px) {
		display: none;
	}
	&--search {
		background-image: url(../images/icons/mobile-search.svg);
		background-repeat: no-repeat;
		background-position: center center;
		right: 70px;
	}
	&--menu {
		background-image: url(../images/icons/mobile-menu.svg);
		background-repeat: no-repeat;
		background-position: center center;
	}
}

@media screen and (max-width:990px) {
	#mobile-menu,
	#mobile-search {
		background-color:$navy;
		display: none;
		width: 100%;
		z-index:99999;
	    &:before {
	        content: '';
	        width: 0; 
	        height: 0; 
	        border-left: 10px solid transparent;
	        border-right: 10px solid transparent;
	        border-bottom: 10px solid $navy;
	        position: absolute;
	        top: -10px;
	        display: block;
	        z-index: 99999;
	    }
	    &.active {
	    	display: block;
	    	height: 1000px;
	    }
	    .form-search {
	    	width: 100%;
	    	max-width: 100%;
	    }
	}
	#mobile-menu {
		a {
			color: #fff;
			text-decoration: none;
			display: block;
			padding: 15px 20px;
		}
		&:before {
	        right: 50px;
		}
	}
	#mobile-search {
		padding: 20px;
		overflow: hidden;
		&:before {
	        right: 95px;
		}
		.search-form {
			display: inline-block;
			width: 100%;
			height: auto;
			.form-wrap {
			    border: none;
			    border-radius: 55px;
			    text-align: left;
			    position: relative;
			    width: 100%;
			    input[type=text],
			    input[type=search] {
				    text-align: left;
				    width:100%;
				    border-radius: 55px;
				    height: 50px
				}
				.btn{
				    background: #007DC5  url(../images/icons/Shape.svg) no-repeat;
				    background-position: center center;
				    border-radius: 100%;
				    width: 50px;
				    height: 50px;
				    padding: 0px;
				    position: absolute;
				    right: 5px;
				}
				input[type=text],
			    input[type=search],
			    input[type=submit],
			    .btn {
					display: inline-block;
					vertical-align: middle;
			    }
			}
		}
	}
}
@media screen and (min-width:990px) {
	#mobile-search {
		display: none;
		visibility: hidden;
	}
	#mobile-menu {
		// li {
		// 	border: none;
		// 	a {
		// 		text-decoration: none;
		// 		display: block;
		// 	}
		// }
		li {
			display: inline-block;
			font-family: $museo;
			@include font-size(18px);
			@include smooth;
			border: none;
			a {
				display: inline-block;
				color: $navy;
				padding: 0.9em 0.8em;
				@include link-reset;
				text-decoration: none;
				&:hover {
					// background: $link;
				}
			}
		}
	}
}
#nav-sub{
	.first{
		display:none;
	}
}
@media screen and (min-width:1130px) {
	#nav-sub{
		.first{
			display:inline-block;
			*display:inline;
			*zoom:1;
		}
	}
}
