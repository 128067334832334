@import "_reset";
@import "_variables";
@import "_spacing";
@import "_sprite";
@import "_typography";
@import "_icons";
@import "_ui";
@import "_nav";
@import "_lightbox";
@import "_cookieyes";

* {
	box-sizing: border-box;
}

body {
	// background: #F9F9F9;
	background-color:#FFFFFF;
	font-family: $calibri;
	line-height: 1.5;
	color: $black;
}

//
.global-container {
	max-width: 1240px;
	margin: 0 auto;
	padding: 0 20px;
	&.no-padding {
		padding: 0px;
	}
}
.footer-wrap {
	padding: 0 20px;
}

a {
	color: $link;
	text-decoration: underline;
	position: relative;
	&:hover {
		text-decoration: none;
		&:focus {
			background-color: none;
			outline: none;
		}
	}
	// &:focus {
	// 	background-color: #ffbf47;
	// 	outline: 3px solid #ffbf47;
	// }
}
.homepage-header-2 {
	position: relative;
	background-position: center bottom;
	background-repeat:no-repeat;
	background-size: cover;
	padding: 2.6em 1em;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	@media screen and (max-width:736px) {
		margin-bottom: 0px!important;
	}
	@media screen and (min-width:426px) {
		padding: 2.6em;
	}
	@media screen and (min-width:687px) {
		padding-top:45px;
		padding-bottom:45px;
		height: auto;
		min-height: 25em;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@include sp-m;
	.homepage-header--mask {
		opacity: 0.84;
		background:rgba(15,66,130,0.5);
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
	form {
		flex: none;
		width: 100%;
		max-width: 580px;
		text-align: center;
		position: relative;
		input[type='text'] {
			width: 100%;
			border-radius: 55px;
			height: 100%;
			@media screen and (min-width:713px) {
				@include font-size(17px);
			}
		}
		input[type='submit'],
		button {
			position: absolute;
			right: 5px;
			top: 5px;
			@media screen and (min-width:713px) {
				@include font-size(17px);
			}
		}
		.btn {
			background: $link url(../images/icons/Shape.svg) no-repeat;
			background-position: center center;
			border-radius: 100%;
			width: 50px;
			height: 50px;
			padding: 0px;
		}
		h1, p {
			color: #fff;
			margin-bottom: 20px;
		}
		p {
			font-family: $museo-light;
			@include font-size(21px);
			@include smooth;
			margin: 45px 0 20px 0;
		}
		.form-wrap {
			border: none;
			border-radius: 55px;
			text-align: left;
			height: 60px;
			position: relative;
			padding: 0px;
			z-index: 99988;
		}
	}
	h1 {
		font-family: $museo;
		// @include font-size(30px);
		@include font-size(25px);
		@media screen and (min-width:768px) {
			@include font-size(36px);
		}
	}
}

.notification-bar {
	background: $link;
	color: #fff;
	text-align: center;
	padding: 1em;
	border-radius: 3px;
	@media screen and (min-width:$grid-start) {
		margin: 0 20px;
		@include sp-l;
	}
	p {
		margin-bottom: 0;
	}
	@include sp-l;
}

.news-pod {
	.news-pod--image {
		@include sp-s;
		img {
			width: 100%;
			display: block;
		}
	}
	h4 {
		@include sp-xs;
	}
}

.small, .meta {
	@include small;
}

.meta {
	@include new-small;
}

.small {
	color: #fff;
}

footer {
	padding: 2.6em 0;
	background: $navy;
	.col-4 {
		vertical-align: middle;
		@include sp;
	}
	hr {
		@include sp;
	}
	.logo {
		@include sp;
	}
	.list-social {
		margin-bottom: 30px;
		li {
		    display: inline-block;
		    *display: inline;
		    *zoom:1;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			margin-right: 10px;
		}
		li a {
			text-align: none;
			display: block;
			&:focus {
				outline: 2px solid #007dc5;
			}
		}
	}
}

.footer--links {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	// max-width: 215px;
	ul {
		@include list-reset;
		li {
			@include heading-three;
			@include smooth;
			@include sp-s;
			a {
				text-decoration: none;
				color: #fff;
				&:hover {
					// color: $blue-dark;
					text-decoration: underline;
				}
			}
		}
	}
}

.footer--mailing-list {
	// max-width: 421px;
	p {
		@include small;
		@include sp-s;
		color: #fff;
		margin-bottom: 30px;
	}
	.h3 {
		color: #fff;
		font-size: 14px;
		@media screen and (min-width:768px) {
			font-size: 24px;
		}

	}
	.btn {
		@media screen and (max-width:600px) {
			width: 100%!important;
		}
	}
}

.footer--contact,
.footer--links,
.footer--mailing-list {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	@media screen and (min-width:600px) {
		padding: 0 20px;
	}
	@media screen and (min-width:768px) {
		width: 33.333%;
	}
}

.footer--gutter {
	padding: 10px 0px;
	@media screen and (min-width:600px) {
		padding: 10px 20px;
	}

}

.category-header, .page-header {
	@include sp-m;
	max-height: 16em;
	overflow: hidden;
	position: relative;
	padding: 20px;
	clear: both;
	img {
		width: 100%;
		display: block;
	}
	h1 {
		margin-bottom: 0;
	}
	.container {
		// padding: 2.6em 20px;
		// padding: 2.6em 20px;
	}
	.container:after {
		content: '';
		display: block;
		padding-top: 1.6em;
		border-bottom: 2px solid $line;
	}
	&.background-image {
		h1 {
			position: absolute;
			left: 20px;
			bottom: 0;
			color: #fff;
			@include sp;
			@media screen and (min-width:$grid-start) {
				left: 40px;
			}
		}
		ul {
			position: absolute;
			bottom: -20px;
			color: #fff;
			@media screen and (min-width:$grid-start) {
				left: 40px;
			}
			a {
				color: #fff;
			}
		}
		.container {
			position: absolute;
			bottom: 0.6em;
			left: 0;
			right: 0;
			padding: 0 20px;
			border: none;
			@media screen and (min-width:$grid-start) {
				bottom: 1.6em;
				margin-top: -100px;
			}
			&:after {
				display: none;
			}
		}
	}
	@media screen and (min-width:$grid-start) {
		.container {
			position: relative;
			// padding: 40px 20px;
			// padding-top: 40px;
			// padding-bottom: 40px;
		}
	}
}

.category-header {
	background: $navy;
	color: #ffffff;
	h1 {
		color: #ffffff;
	}
	.container:after {
		content: '';
		display: none;
		padding-top: 0;
		border-bottom: none;
	}
	@media screen and (max-width:1045px) {
		margin-bottom: 0px;
	}
}

.page-header {
	@include sp;
	@media screen and (min-width:$grid-start) {
		.container {
			position: relative;
			padding: 2.6em;
		}
	}
}

.page-content {
	@include sp-xxl;
	ul, ol {
		margin-left: 1em;
	}
	.sidebar {
		ul {
			margin-left: 0;
		}
		.pullout-box {
			padding-left:0px;
		}
	}
}

.sidebar {
	width: 100%;
	max-width: 100%;
	height:auto;
	// padding: 0px;
	@media screen and (min-width:1045px) {
		width: 33%;
		// padding-left: 20px;
	}
}

.js-cookie-bar {
	background: $blue;
	color: #fff;
	padding: 1.6em;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99999;
	p {
		@include sp-m;
		&:last-of-type {
			@include sp;
		}
		@media screen and (min-width:970px) {
			margin-bottom: 0;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	a {
		font-size: 16px;
		font-size: 1em;
		font-family: $museo;
		color: #fff;
	}
	.js-cookie-bar-close {
		font-size: 14px;
		@media screen and (min-width:970px) {
			font-size: 16px;
		}
	}
}

@media screen and (min-width:970px) {
	.js-cookie-bar--left {
		display: inline-block;
		vertical-align: middle;
		width: 66.6%;
	}
	.js-cookie-bar--right {
		display: inline-block;
		vertical-align: middle;
		width: 33.3%;
		text-align: right;
	}
}

.list-recent-publications {
	list-style-type: none;
	&.mini {
		h4 {
			margin-bottom: 0;
		}
		p {
			margin-bottom: 0;
		}
		hr {
			@include sp;
		}
	}
	li{
		p{
			&.event-fix{
				margin-bottom:0;
			}
		}
		.event-copy{
			display:inline-block;
			*display:inline;
			*zoom:1;
			vertical-align:top;
			width:100%;
			margin-top:2.81rem;
			@media screen and (min-width:970px) {
				width:66%;
				padding-right:20px;
			}
		}
		.event-image{
			display:none;
			@media screen and (min-width:970px) {
				display:inline-block;
				*display:inline;
				*zoom:1;
				vertical-align:top;
				width:33%;
				img{
					width:100%;
				}
			}
		}
	}
}

.publication--status,
.publication--dates {
	display: block;
	color:$new-header-bg;
	// font-size: 1em;
	font-size: 14px;
	padding:0px;
	text-transform: capitalize;
	padding-right: 1em;
}

.councillor-specific {
	display: block;
	background-color: #F2F8FC;
	padding: 30px;
}

.councillor-photo {
	text-align: center;
	border: solid 1px $new-border;

	@media screen and (min-width:700px) {
		margin-right: 30px;		
	}
	img {
		// @include glow;
		display: block;
		width: 100%;
		max-width: 180px;
		height: auto;
	}
}

.councillors-district {
	border-bottom: solid 1px $new-border;
	padding-bottom: 15px;
}

.councillor-photo,
.councillor-info,
.councillor-contact,
.councillor-committee {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	vertical-align: top;
}

.councillor-contact,
.councillor-committee {
	h2,
	h3 {
		margin-bottom: 20px;
	}
}

.councillor-contact {
	@media screen and (min-width:700px) {
		padding-right: 30px;	
	}
}

.councillor-email{
	clear:both;
	display:block;
}

.list-councillors--councillor {
	.col-4 {
		width: 100%;
		text-align: center;
		@media screen and (min-width:670px) {
			max-width: 50%;
		}
		@media screen and (min-width:700px) {
			max-width: 25%;
			text-align: left;
		}
	}
}

.list-councillors {
	h1 {
		color:$navy;
	}
	h2 {
		border-bottom: solid 1px $new-border;
		padding-bottom:20px;
		font-size:24px;
		margin-left: 0px!important;
	}
	h3 {
		font-size: 1em;
	}
	a {
		text-decoration: none;
	}
	p {
		color: #7F9CB6;
	}
	img {
		border: solid 1px $new-border;
		border-radius:0;
	}
}

.container.atoz {
	padding: 0;
	@media screen and (min-width:768px) {
		// padding: 0 20px;
	}
	h2 {
		margin-left: 20px;
		&.no-margin-left {
			margin-left: 0px!important;
		}
	}
	.pullout-box {
		padding-left: 0px;
	}
}

.container.no-padding {
	padding-left:0px;
	padding-right:0px;
	// @media screen and (min-width:600px) {
	// 	padding-left:20px;
	// 	padding-right:20px;
	// }		
}

.post--image {
	@include sp-m;
	img {
		// border-radius: 3px;
	}
}

.news-item {
	.col-3, .col-2-3 {
		vertical-align: middle;
		img {
			display: block;
		}
	}
	&.grid-row {
		hr {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
}

#a_to_z {
	padding: 0 20px;
	@media screen and (min-width:768px) {
		padding: 0px;
	}
	h2 {
		margin-left: 0px;
	}
	.grid-row {
		margin-left: 0px;
		margin-right: 0px;
	}
	.col-3 {
		padding-left: 0px;
	}
}

.payment-methods {
	display: inline-block;
	*display: inline;
	zoom: 1;
	vertical-align: top;
	margin-top: 12px;
}
//
//
// Print
//
//

#news-carousel{
	a{
		display:inline-block;
		*display:inline;
		*zoom:1;
		width:150px;
		
		-moz-box-sizing:border-box;
		box-sizing:border-box;
		padding:5px;
		overflow:hidden;
		position:relative;
		vertical-align:top;
		img{
			display:block;
			position:relative;
			margin:0;
			padding:0;
		}
	}
}

// NEW STYLES AUG 2016

.arrow-down {
	background: url(../images/icons/arrow-down.svg) no-repeat;
	display: block;
	clear: both;
	width: 50px;
	height: 50px;
	margin: 0 auto;
	cursor: pointer;
}
.mea_services,
.council-services {
	@media screen and (min-width: 768px){
		margin-left: 0px;
		margin-right: 0px;
	}
	.col-4 {
		text-align: center;
		// padding:0 5px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		cursor: pointer;
		width: 25%;
		max-width: 25%!important;
		@media screen and (min-width: 768px){
			padding: 20px;
			max-width: 25%!important;
		}
		a {
			text-decoration: none;
			font-size: 13px;
			line-height: 20px;
			@media screen and (min-width: 768px){
				font-size: 16px;
				line-height: 20px;
			}
		}
		h3 {
			display: block;
			font-family: $museo-bold;	
			color: #00386C;
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 10px;
			@media screen and (min-width: 768px){
				font-size: 24px;
				color: #00386C;
				line-height: 30px;
			}
			a {
				text-decoration: none;
				font-size: 14px;
				line-height: 20px;
				@media screen and (min-width: 768px){
					font-size: 24px;
					color: #00386C;
					line-height: 30px;
				}
			}
		}
		&:hover {
			@media screen and (min-width:768px) {
				background-color:$link; 
				color: #fff;
				h3,
				.icon,
				span,
				a {
					color: #fff;
				}
				.icon,
				.ui_icon {
					color:#fff;

				}
				.service-icon {
					img {
						display: none;
						&.white_icon {
							display: block;
						}
					}
				}
				.white-icon {
					display: inline-block;
				}
			}

		}
	}	
	.col-4 {
		// @media screen and (max-width:767px){
		// 	border-left: solid 1px #CCD8E2;
		// 	border-top: solid 1px #CCD8E2;

		// 	&:nth-child(4n+1){
		// 		border-left: none;
		// 	}
		// 	&:nth-child(1n+5){
		// 		border-top: solid 1px #CCD8E2;
		// 	}
		// }
		@media screen and (min-width:768px) {
			border-left: solid 1px #CCD8E2;
			padding-top: none;
			&:nth-child(4n+1){
				border-left: none;
			}
			&:nth-child(1n+5){
				border-top: solid 1px #CCD8E2;
			}
		}
	}	
	.icon,
	span,
	a {
		display: block;
		clear: both;
	}
}

#services {
	&.container {
		@media screen and (max-width:768px) {
			padding:20px 0px;
		}
	}
}
.council-services {

	@media screen and (min-width:768px) {
		margin-bottom: 40px;
	}
	.block {
		margin-bottom: 0px;

	}
}
.bd-category {
	.council-services {
		padding-top:20px;
	}
}

.council-services {
	.col-4 {
		text-align: center;
		vertical-align: middle;
		.vh-align {
			width: 100%;
			height: 120px;
			text-align: center;
			display: table;
			@media screen and (min-width:768px) {
				height: 240px;
			}
		}
		.vh-item {
		    display: table-cell;
		    vertical-align: middle;
		    .subsections-links {
		    	font-family: $museo-light;
				text-decoration: underline;
				display: block;
				display: none;
				@media screen and (min-width:736px) {
					display: block;
				}
		    }
		}
	}
	@media screen and (max-width:736px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}
.service-icon {
	display: block;
	// min-height: 100px;
	// min-height: 50px;
	// padding-bottom: 0px;
	width: 100%;
	margin: 0 auto;
	// vertical-align: bottom;
	// position: relative;
	// @media screen and (min-width:768px) {
	// 	width: auto;
	// 	padding-bottom: 30px;
	// 	min-height: 100px;
	// }
	// background-color: green;
	// vertical-align: top;
	// img {
		// vertical-align: top;
		// display:block;
		// position: absolute;
		// bottom: 20px;
		// margin: 0 auto;
		// transform: translateY(25%);
		// -webkit-transform: translateY(25%);
		// -moz-transform: translateY(25%);
		// left: 0px;
		// right:0px;
		// max-width: 50%;
		// @media screen and (min-width:768px) {
		// 	bottom: 30px;
		// 	max-width: 100%;
		// }
		// &.white_icon {
		// 	display: none;
		// }

	// }

	h3 {
		clear: both;
		display: block;
	}
}

.service-icon--icons {
 	display: block;
 	position: relative;
 	height: 110px;
	@media screen and (max-width:736px) {
		height: 70px;
	}
}
.blue-icon,
.white-icon {
	position: relative;
	display: inline-block;
	transform: translateY(15%);
	-webkit-transform: translateY(15%);
	-moz-transform: translateY(15%);
	img {
		@media screen and (max-width:736px) {
			max-width: 70%;
		}
	}
}
.white-icon {
	display: none;
}
.services-links {
	display: inline-block;
	vertical-align: top;
}
.sec-title.landing-page {
	@media screen and (max-width:736px) {margin-bottom: 30px!important;}
}

@media print {
	nav, .pullout-box, .homepage-header-2, .nav-main-wrap, footer, .news-and-events, form {
		display: none;
	}
	.alert {
		padding: 0;
		font-size: 1.6em;
	}
}
.block  {
	display: block;
	text-align: center;
	// margin-top: 30px;
	margin-bottom:10px;

	@media screen and (min-width:768px) {
		margin: 40px auto;
	}
}

.slider {
	// background: $line;
	display: block;
	height: auto;
	width: 100%;
	position: relative;
	@media screen and (min-width:768px) {
		height: 600px;
	}
	.slider-image {
		background-size:cover;
		background-repeat:no-repeat;
		background-position: center center; 
		display: block;
		position:relative;
		width:100%;
		// height:100%;
		height: 315px;
		overflow: hidden;
		// .mobile-image {
		// 	display: none;
		// 	@media screen and (min-width:768px) {
		// 		display: none;
		// 	}
		// }
		img {
			max-width: 100%;
			height: auto;
		}
		@media screen and (min-width:768px) {
			height:100%;
			max-height: 600px;
		}
	}
	.content {
		background-color: rgba(0,56,108,0.8);
		display: block;
		color: #fff;
		position: relative;
		width: auto;
		padding: 20px;
		padding-bottom: 60px;
		min-height: 400px;
		@media screen and (min-width:768px) {
			position: absolute;
			bottom: 0px;
			left: 0px;
			padding: 30px 100px 90px 30px;
		}
		@media screen and (min-width:960px) {
			padding: 30px 100px 90px 170px;
			bottom: 40px;
			min-height: auto;
		}
		p {
			font-size: 16px;
			margin-bottom: 0px;
		}
		.txt--large {
			font-size: 24px;
			line-height: 1.2;
			@media screen and (min-width:768px) {
				font-size: 30px;
			}

		}		
	}
	.slider-copy {
		width: 100%;
		max-width: 715px;
	}
}

.txt {
	color: #FFFFFF;
	display: block;
	line-height: 1.1;
	&--large {
		font-size: 30px;
		margin-bottom: 20px;
		font-family: $museo;
	}
	&--medium {
		font-size: 20px;
	}
}

.link {
	line-height: 1.1;
	text-decoration: none;
	&--primary {
		font-size: 20px;
		color: $navy;
	}
	&--secondary {
		color: $new-blue;
	}
	&:hover {
		text-decoration: underline;
	}
}

.owl-controls {
	// position: absolute;
	color: #ffffff;
	width:250px;
}
.owl-prev,
.owl-next {
	background-color: $new-blue;
	font-family: $museo-bold;
	@include font-size(15px);
	padding: 0.5em;
	border-radius: 56px;
	cursor: pointer;
	@include smooth;
	text-decoration: none;
	text-align: center;
	-webkit-appearance:none;
	display:inline-block;
	width: 70px;
	text-transform: capitalize;
}
.owl-next{
	float: right;
}
.owl-dots {
    position: absolute;
    top: 10px;
    right: 125px;
	.owl-dot {
		display: inline-block;
		width: 15px;
		height: 15px;
		border:2px solid $link;
		border-radius:50%;
		margin:0 5px;
		&.active {
			background-color:$link;
		}
	}
}
.relative {
	position: relative;
}
.home-slider {
	
	
	.slider-copy {
		a {
			color: #ffffff;
			text-decoration: none;
		}
	}
	.owl-dots {
		top: auto;
		bottom: 34px;
		left: 107px;
		right: auto;
		@media screen and (min-width:768px) {
			bottom: 74px;
			left: 252px;
		}
	}

}
.home-slider-owl-controls {
	position: absolute;
	bottom: 30px;
	left: 20px;
	z-index: 1;
	color: #ffffff;
	@media screen and (min-width:960px) {
		bottom: 70px;
		left: 170px;
	}
}
.homepage-news-events {
	margin: 0 auto;
	display: block;
	padding: 40px 0;
	.h2 {
		margin-bottom: 20px;
	}
	.pullout-box {
		p {
			margin-bottom: 0px;
		}
		li {
			margin-bottom: 25px;
		}
	}
	.link {
		display: block;
		font-family: $museo-bold;
		font-size: 20px;
		color: #00386C;
		line-height: 30px;
	}
}
.news-and-events {
	padding: 0px;
	a {
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
.home-events {
	list-style: none;
	display: block;
	a {
		font-family:$museo;
		font-size:20px;
		color:$navy;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
.explore-the-borough {
	padding: 40px 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display:block;
	overflow: hidden;
	background-color:$blue-light;

	@media screen and (min-width:850px) {
		background-repeat: no-repeat;
		background-image: url(../images/Borough-Map.png);
		background-position:-400px 0px;
	}
	@media screen and (min-width:1050px) {
		background-image: url(../images/Borough-Map.png);
		background-position:-112px 0px;
		padding: 50px 0;
		min-height: 730px;
	}
	.copy {
		display: block;
		margin-bottom: 25px;
		p {
			margin-bottom: 20px;
			font-family:$museo-light;
			color:#00172D;
			&:nth-of-type(1) {
				font-family:$museo;
			}
		}
	}
	.h2 {
		font-family:$museo;
		font-size: 24px;
		line-height: 1.1;
		color:$navy;
		padding-bottom:20px;
		margin-bottom: 25px;
		border-bottom: solid 1px #CCD8E2;
		@media screen and (min-width:768px) {
			font-size: 30px;
			line-height: 1.1;
		}

	}
	.col-3 {
		float: none;
		max-width: 100%;
		@media screen and (min-width:850px) {
			max-width: 55%;
			float: right;
		}
		@media screen and (min-width:1050px) {
			// max-width: 33%;
			// padding: 0px 20px 0px 40px;
			max-width: 45%;
			padding: 0px 40px 0px 20px;
		}
	}
}
.float-right {
	float: none;
	@media screen and (min-width:768px) {
		max-width: 55%;
		float: right;
	}
}
.footer-tel,
.footer-email {
	color: #fff;
	display:block;
}
.footer-tel {
	font-size: 30px;
}
.footer-email {
	font-size: 16px;
	margin-bottom: 30px;
	&:hover {
		text-decoration: none;
	}
}
.footer-nav {
	width: 100%;
	display:inline-block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:first-of-type {
		@media screen and (max-width:768px) {margin-bottom: 0px;}
	}
	@media screen and (min-width:768px) {
		width: 50%;
		padding: 0 15px;
	}
	li {
		border-bottom: solid 1px #406990;
		margin-bottom: 0px!important;
	}
	a {
		font-size: 14px;
		line-height: 1.1;
		display: block;
		padding: 15px 0;
	}
}
.footer-logo {
    background-image: url(../images/meawhite-logo.png);
    background-size: 100%;
    background-repeat: no-repeat;
    display: block;
    width: 150px;
    height: 65px;
    text-indent: -99999px;
    margin-bottom: 0px;
    @media screen and (min-width:990px) {
	    width: 200px;
	    height: 75px;
	    margin-bottom: 30px;
    }
}
address {
	color: #fff;
	font-style: normal;
	span {
		display: block;
	}
}
//landing pages
.introduction {
	color: $navy;
	font-size:18px;
	line-height: 24px;
	max-width: 1000px;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 30px;
	@media screen and (min-width:768px) {
		font-size:36px;
		line-height: 48px;
		padding-bottom: 40px;
	}
}

.ch-header,
.search-form {
	display: inline-block;
	*display: inline;
	*zoom: 1;
	vertical-align: top;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.search-form {
	display:none;
	@media screen and (min-width:880px) {
		display: block;
		// width: 31%;
		width: 370px;
		height: auto;
		float: right;
		margin-top: 15px;
		.form-wrap {
		    border: none;
		    border-radius: 55px;
		    text-align: left;
		    position: relative;
		   	z-index: 99999;
		    input[type=text],
		    input[type=search] {
			    text-align: left;
			    width:100%;
			    border-radius: 55px;
			    height: 50px
			}
			.btn{
			    background: $link url(../images/icons/Shape.svg) no-repeat;
			    background-position: center center;
			    border-radius: 100%;
			    width: 50px;
			    height: 50px;
			    padding: 0px;
			    position: absolute;
			    right: 5px;
			}
			input[type=text],
		    input[type=search],
		    input[type=submit],
		    .btn {
				display: inline-block;
				vertical-align: middle;
		    }
		}
	}
}
#search-result-spec  {
	li {
		display: block;
		border-bottom: solid 1px #CCD8E2;
		padding-bottom: 30px;
		margin-bottom: 30px;
		h4 {
		    margin-bottom: 0;
		    font-family: $museo-bold;
		}
		.ex-sml {
		    font-size: 14px;
		    color: #7F9CB6!important;
		    margin-bottom: 30px!important;
		}	
		p {
			font-family: $museo-light;
			font-size: 16px;
			color: #333333;
			margin-bottom: 0px;
		}	
	}
}

.results {
	padding: 40px;
	margin-bottom: 50px;
	background-color: #F2F8FC;
	display: block;
	p {
		color:#00386C;
		margin-bottom: 0px;
		font-family: $museo;
		font-size: 20px;
	}
}
//specific pages
.page-content {
	h1,h2,h3 {
		color: $blue-dark;
	}
	h1 {
		@media screen and (min-width:768px) {margin-top: 12px;}
	}
}

.page-content {
	@media screen and (max-width:600px) {
		padding-left: 0px;
		padding-right: 0px;
	}
	@media screen and (max-width:768px) {
		margin-bottom: 40px;
	}
}
.breadcrumb {
	@media screen and (max-width:600px) {
		a,
		.last {
			font-size: 14px;
		}
	}
}
.category-header .container, 
.page-header .container {
	@media screen and (max-width:600px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}
//new landing
.latest-news-patch {

	@media screen and (max-width:600px) {
		padding:20px 20px 0 20px;
	}
	@media screen and (min-width:601px) {
		margin-bottom: 40px;
	}
	.article {
		position: relative;
		// background-color:#F2F8FC;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
		&--large-img,
		&--txt  {
			display: block;

			@media screen and (min-width:768px) {
				display: inline-block;	
				vertical-align: top;
				width: 50%;
			}	
		}
		&--large-img {
			position: relative;
			img {
				margin-bottom: 0px;
			}
			@media screen and (min-width:1045px) {
				width: 65%;
			}
			&.e-block {
				width: 100%;
				display: block;
				img {
					margin-bottom: 0px;
				}
			}
		}
		&--txt {
			// min-height: 370px;
			@media screen and (min-width:1045px) {
				width: 35%;
			}
			&.e-block {
				width: 100%;
				display: block;
				.copy {
					padding: 20px;
				}
			}
			.copy {
				padding: 20px;
				@media screen and (min-width:768px) {
					padding: 20px 40px;
				}
				@media screen and (min-width:1045px) {
					padding: 50px 40px 50px 40px;
				}
			}
		}
	}
	a {
		text-decoration:none;
		cursor: pointer;
		&:hover {
			text-decoration:underline;
		}
	}
	.publication--status,
	.publication--dates {
		color:$new-header-bg;
		margin-bottom: 30px;
	}
	.grid-row {
		margin-left: 0px;
		margin-right: 0px;
		.col-3 {
			padding-left: 0px;
		}
	}
}
.latest-news {
	background-color:#F2F8FC;
	&.no-bg {
		background-color: transparent;
		.copy {
			background-color:#F2F8FC;
		}
	}

	.owl-controls {
		position: relative;
		float: none;
		// margin-top: -70px;
		// margin-left:  20px;
		bottom: 20px;
    	left: 20px;

		
		@media screen and (min-width:768px) {
			float: right;
			// right: 12%;
			margin-right: 12%;
			margin-left: 0px;
			margin-top: -70px;
		}
		@media screen and (min-width:1045px) {
			margin-right: 12%;

		}
	}
	.owl-dots {
		right:77px;
	}
	h4 {
		font-size: 24px;
	}
}
.list-recent-publications {
	margin-left: 0px!important;
	padding: 20px;
	@media screen and (min-width:601px) {padding: 0px;}
	.grid-row {
		margin-left: 0px!important;
		margin-right: 0px!important;
		.col-3 {
			padding-left: 0px;
		}
	}
	a {
		text-decoration:none;
		cursor: pointer;
		color:#00386C;
		&:hover {
			text-decoration: underline;
		}
	}
	h2 {
		margin-bottom: 20px;
	}
	.tenders-heading,
	h4 {
		font-size: 20px;
		font-family: $museo-bold;
	}

}

.article {
	font-family: $museo-light;
	text-decoration:none;
	img {
		width: 100%;
		height: auto;
		display: block;
		margin-bottom: 20px;
	}
	h4,
	.link {
		display: block;
		font-family: $museo-bold;
		font-size: 20px;
		color: #00386C;
		line-height: 30px;
		// margin-bottom: 10px;
	}
	a {
		display: block;
		position: relative;
	}
}
.article-specific {
	font-family: $museo-light;
	padding: 30px 0px;
	@media screen and (min-width:1045px) {
		padding: 0px 20px;
	}
	.publication--status {
		margin-bottom: 30px;
	}
	p {
		font-size: 16px;
	}
	.news-summary {
		p {
			font-family: $museo-light;
			font-size: 18px;
		}	
	}
}
.background {
	&--primary {
		background-color:$navy;
	}
	&--secondary {
		background:$secondary-bg;
	}
}

.news-sidebar {
	position: relative;
	padding:0 20px;
	h2,
	article {
		border-bottom: solid 1px $new-border;
		color:$navy;
		font-size: 20px;
	}
	h2 {
		padding-bottom: 25px;
		margin-bottom: 0px;
		font-size: 24px;
	}
	article {
		padding: 25px 0px;
		&:last-of-type {
			margin-bottom: 30px;
		}
	}
	a {
		color:$navy;
		text-decoration: none;
		cursor: pointer;
		font-family: $museo-bold;
		margin-bottom: 10px;
		display: block;
		font-size: 20px;
	}
	.publication--dates {
		display: block;	
	}
	.bttn {
		color: #7F9CB6;
		width: 200px;
		font-size: 16px;
		margin-bottom: 0px;
		&:after {
			content:'';
			right: 25px;
		}
	}
	.block {
		display: block;
		margin: 0px auto;
	}
	&--inner {
		padding:30px;
	}
	@media screen and (max-width:1045px) {
		max-width:100%;
	}
}
.intro {
	font-size: 18px;
	border-top: solid 1px $new-border; 
	padding-top: 30px;
}

.recently-added{
	border-bottom: solid 1px $new-border; 
	padding-bottom:10px;
}

.tenders {
	@media screen and (max-width:1045px) {
		padding-top: 20px;
	}
	h2 {
		border-bottom: solid 1px $new-border; 
		padding-bottom:10px;
	}
	#publication-search {
		background-color: $secondary-bg;
		border: none;
		padding: 40px;
		input[type=text] {
			border-color: $new-border;
		}
		.tender-col {
			display: block;
			vertical-align: middle;
			max-width: 100%;
			@media screen and (min-width:768px) {
				display: inline-block;
				vertical-align: middle;
				max-width: 20%;
			}
			&.input {
				width: 100%;
				margin-bottom: 20px;
				@media screen and (min-width:768px) {
					padding-right:20px;
					margin-bottom: 0px;
					max-width: 80%;
				}
			}
		}
	}
	.list-recent-publications {
		li {
			border-bottom: solid 1px $new-border; 
			margin-bottom: 30px;
		}
	}
	.publication--status {
		padding-right: 0px;
		&:after {
			content:'|';
			display: inline-block;
			padding:0 5px 0 10px;
		}
	}
	.publication--status, 
	.publication--dates {
		display: inline-block;
	} 
	.e-source {
		background-color: $secondary-bg;
		padding: 40px;
		margin-bottom: 30px;
		p {
			margin-bottom: 0px;
		}
		.e-sourcing-heading {
			font-size: 18px;
			border-bottom: solid 1px $new-border; 
			display: block;
			color:$navy;
			padding-bottom: 15px;
			margin-bottom: 15px;
		}
	}
}

#publication-search {
	font-size: 18px;
	display: block;
	color:$navy;
	background-color: $secondary-bg;
	border: none;
	padding: 40px;
	margin-bottom: 40px;

}

#bin-search,
#penalty-payment,
#online_penalty_payment,
#bin-purchase-form {
	background-color:transparent;
	border: none;
	.styled-select {
		select {
			background: transparent;
			width: 100%;
			max-width: 450px;
			padding: 10px 20px;
			font-size: 16px;
			line-height: 1.1;
			height: 50px;
			-webkit-appearance: none;
			-moz-appearance: none;			
			border: solid 1px $new-border; 
		}
	}
	h4 {
		border-bottom: solid 1px $new-border; 
		padding: 0 0 20px 0;
	}
	.unit {
		padding-left: 0px!important;
		padding-right:0px!important;
		max-width: 450px;
	}
	input[type='text'] {
		border: solid 1px $new-border; 
	}
	form {
		background-color:transparent;
		border: none!important;
	}

}

// .bd-standard-page {
// 	#bin-purchase-form {
// 		form {
// 			background-color:transparent;
// 			border: none!important;
// 			.styled-select {
// 				select {
// 					background: transparent;
// 					width: 100%;
// 					max-width: 450px;
// 					padding: 10px 20px;
// 					font-size: 16px;
// 					line-height: 1.1;
// 					height: 50px;
// 					-webkit-appearance: none;
// 					-moz-appearance: none;			
// 					border: solid 1px $new-border; 					
// 				}

// 			}
// 		}
// 	}	
// }

.styled-select{
	position:relative;
	width: 100%;
	max-width: 450px;
	&:after {
		content:'';
		background-image: url(../images/icons/select.svg);
		background-repeat:no-repeat;
		width:9px;
		height:21px;
		display: block;
		position: absolute;
	    top: 50%;
	    margin-top: -11px;
	    right: 22px;
	}
	select {
		background: transparent;
		width: 100%;
		padding: 10px 20px;
		font-size: 16px;
		line-height: 1.1;
		height: 50px;
		-webkit-appearance: none;
		-moz-appearance: none;			
		border: solid 1px $new-border; 
		cursor:pointer;
		color: #7F9CB6;
	}	
} 
.community-intro {
	background-color: $secondary-bg;
	padding:40px;
	h3 {
		font-family: $museo;
		font-size: 20px;
		margin:0px;
	}
	&.margin-bottom {
		margin-bottom: 50px;
	}
}
.section-links {
	.heading-min {
		margin:0px;
		&:hover {
			background-color: $secondary-bg;
		}
	}
	a {
		border-top: solid 1px $new-border;
		padding: 15px 20px;
		width: 100%;
		display: block;
		&:after {
			content:'';
			display: block;
			width:12px;
			height: 12px;
			background:url(../images/icons/pullout-box-right-arrow.svg);
			background-repeat: no-repeat;
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top:-6px;
		}
		&:hover {
			text-decoration: underline;
		}
	}
}
.sub-section-links {
	padding-top:20px;
	padding-bottom:20px;
	padding-left: 0px;
	padding-right:0px;
}
.penalty-fees {
	background:$secondary-bg;
	border: solid 1px $new-border;
	padding: 30px!important;
	.unit {
		width: 100%;
		max-width: 762px!important;
	}
}
.col-1 {

	@media screen and (min-width:600px) {
		padding: 0 20px;
	}
}
// .styled-checkbox {
// 	   // border: solid 1px $new-border; 
// 	   // width: 25px;
// 	   // height: 25px;
// 	input[type='checkbox'] {

// 	   width: 25px;
// 	   height: 25px;			
// 	}
// }

figure {
	background:$secondary-bg;
	margin-bottom: 30px;
	img {
		width: 100%;
		height: 280px;
	}
	figcaption {
		padding: 40px;
		color:$navy;
		p {
			margin:0px;
			font-size: 20px;
		}
	}
}
.sub-sections {
	margin:0px!important;
	padding:0px;
	list-style:none;
	border-bottom: solid 1px $new-border;

	li {
		a {
			border-top: solid 1px $new-border;
			color:$navy;
			padding: 15px 20px;
			width: 100%;
			display: block;
			text-decoration: none;
			cursor: pointer;
			font-size: 16px;
			font-size: 1em;
			font-family: $museo;
			&:after {
				content:'';
				display: block;
				width:12px;
				height: 12px;
				background:url(../images/icons/pullout-box-right-arrow.svg);
				background-repeat: no-repeat;
				position: absolute;
				right: 30px;
				top: 50%;
				margin-top:-6px;
			}
			&:hover {
				background-color:$secondary-bg;
			}
		}
	}
}
iframe  {
	width: 100%;
}
.upcoming-events {
	h2 {
		border-bottom: solid 1px $new-border;
		padding-bottom: 20px;
	}
	@media screen and (max-width:1045px) {
		max-width: 100%;
		padding: 0px;
		padding-top:30px;
	}
	.pullout-box {
		padding-top: 0px;
	}
	&--inner {
		@media screen and (min-width:1045px) {padding-left: 40px;}
	}
}

.ca_hero_image {
	display: block;
	clear: both;
	img {
		display: block;
		width: 100%;
	}
}
.ca-introduction {
	background-color: #F2F8FC;
	font-family: $museo;
	font-size: 18px;
	color: #00386C;
	line-height: 26px;
	padding:20px;
	margin-bottom: 20px;
	@media screen and (min-width:768px) {
		font-size: 20px;
		line-height: 30px;
		padding:40px;
		margin-bottom: 40px;
	}
	p {
		font-family: $museo;
		font-size: 20px;
		line-height: 30px;
		&:last-of-type {
			margin-bottom: 0px;
		}
	}
}

.gutter-images,
.copy--right {
	display: inline-block;
	vertical-align: middle;
	color: #fff;
	font-family: $museo-light;
	font-size: 14px;
	width: 100%;	
	@media screen and (min-width:1045px) {
		width: 50%;	
	}
	a {
		color: #fff;
		text-decoration: none;
		font-family: $museo-light;
		&:hover {
			text-decoration: underline;
		}
	}
	span {
		margin: 0 10px;
	}
	.copyright-text {
		display: inline-block;
	}
}
.copy--right {
	text-align: left;
	@media screen and (min-width:1045px) {
		text-align: right;
	}
}
.gutter-images {
	margin-bottom: 20px;
	@media screen and (min-width:768px) {
		margin-bottom: 0px;
	}
	#__ba_panel {
		vertical-align: middle;
	}
	img {
		display: inline-block;
		margin-right: 5px;
		vertical-align: middle;
		margin-bottom: 10px;
		@media screen and (min-width:768px) {
			margin-bottom: 0px;
		}
		&.worldpay {
			margin-left: 5px;
		}
	}
}


.owl-carousel {
	display: block;
	clear:both;
}


//July 2017 updates
.badge {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 9;
	padding: 10px 20px;
	color: #FFF;
	text-transform: uppercase;
	&.sml-badge {
		font-size: 11px;
		padding: 5px 10px;
	}
	&.Green {
		background-color: #59b654;
	} 
	&.Pink {
		background-color: #ffb6c1;
	}
	&.Red {	
		background-color: #ff0000;
	}
	&.Blue {
		background-color: #063c76;
	}
	&.Orange {
		background-color: #f47900;
	}
}
.infinite-container {
	display: block;
	overflow: hidden;
	margin: 0 -20px;
}
.infinite-item {
	display: block;
	width: 100%;
	float: left;
	padding: 0 20px;
	@media screen and (min-width:580px) {
		width: 50%;
	}
	@media screen and (min-width:768px) {
		width: 33.333%;
	}
}

#__ba_panel {
	display:block;
	width: 120px;
	height: 52px;
	position: fixed;
	bottom: 10px;
	right: 0px;
	z-index: 99999999;
}
//
//  Accessibility Updates
//  _____________________________________________
.customPrevBtn, 
.customNextBtn {
	color: #FFFFFF;
}


// @media only screen and (max-device-width: 768px) {

// 	#__ba_panel._ba_manual { position: fixed !important; bottom: 10px !important; z-index: 99999999 !important; }

// 	#__ba_panel {
// 		display:block !important;
// 		width: 120px;
// 		height: 52px;
// 		position: fixed !important;
// 		bottom: 10px !important;
// 		right: 0px !important;
// 		z-index: 99999999 !important;
// 	}
// }
.gw-launchpad-icon-button {
	&:focus {
		outline: 2px solid #007dc5;
	}
}
*:focus {
	outline-color: #00386c !important;
}
.visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px; 
    width: 1px;
    margin: -1px; 
    padding: 0; 
    border: 0;
}