@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal; }

body {
  line-height: 1.6; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

* {
  box-sizing: border-box; }

img {
  max-width: 100%;
  height: auto !important; }

.sp-0 {
  margin-bottom: 0; }

.sp-xs {
  margin-bottom: 5px;
  margin-bottom: 0.31rem; }

.sp-s {
  margin-bottom: 10px;
  margin-bottom: 0.63rem; }

.sp {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.sp-m {
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }

.sp-l {
  margin-bottom: 60px;
  margin-bottom: 3.75rem; }

.sp-xl {
  margin-bottom: 75px;
  margin-bottom: 4.69rem; }

.sp-xxl {
  margin-bottom: 120px;
  margin-bottom: 7.5rem; }

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0px; }

.grid-row {
  margin-left: 0px;
  margin-right: 0px; }
  @media screen and (min-width: 600px) {
    .grid-row {
      margin-left: -20px;
      margin-right: -20px; } }

.col-2 {
  display: inline-block;
  vertical-align: top;
  width: 100%; }
  @media screen and (min-width: 600px) {
    .col-2 {
      max-width: 50%;
      padding: 0 20px; } }

.col-3 {
  display: inline-block;
  vertical-align: top;
  width: 100%; }
  @media screen and (min-width: 600px) {
    .col-3 {
      max-width: 50%;
      padding: 0 20px; } }
  @media screen and (min-width: 1050px) {
    .col-3 {
      max-width: 33%; } }

.col-2-3 {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 0 20px; }
  @media screen and (min-width: 1050px) {
    .col-2-3 {
      max-width: 66%; } }

.col-4 {
  display: inline-block;
  vertical-align: top;
  width: 100%; }
  @media screen and (min-width: 600px) {
    .col-4 {
      max-width: 50%;
      padding: 0 20px; } }
  @media screen and (min-width: 1050px) {
    .col-4 {
      max-width: 25%; } }

.icon, a[target=_blank]:after {
  background-image: url("../../images/icons/sprite.png"); }

.icon-logo {
  background-image: url("../../images/icons/new-mea-logo.svg");
  background-size: 100%;
  background-repeat: no-repeat; }
  .icon-logo.logo-small {
    background-position: 0px -80px;
    width: 151px;
    height: 65px;
    display: inline-block; }
    @media screen and (min-width: 874px) {
      .icon-logo.logo-small {
        display: none; } }
  .icon-logo.logo {
    background-position: 0px 0px;
    width: 200px;
    height: 75px;
    display: none; }
    @media screen and (min-width: 874px) {
      .icon-logo.logo {
        display: inline-block; } }

@media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3 / 2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .icon, a[target=_blank]:after {
    background-image: url("../../images/icons/sprite@2x.png");
    background-size: 229px 299px; }
  .icon-logo {
    background-image: url("../../images/icons/new-mea-logo.svg");
    background-size: 100%; } }

.icon.menu, a.menu[target=_blank]:after, a.menu[target=_blank]:after {
  background-position: -2px -2px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 14px;
  height: 10px;
  float: right; }

.ui_icon {
  background-image: url("../images/icons/new-sprite.svg");
  background-size: auto;
  background-repeat: no-repeat;
  display: block; }
  .ui_icon.social {
    width: 35px;
    height: 35px; }
    .ui_icon.social.twitter {
      background-position: 0px -105px; }
    .ui_icon.social.facebook {
      background-position: -45px -105px; }
    .ui_icon.social.instagram {
      background-position: -90px -105px; }

h1, p, ul, ol, table {
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }

h2, h3, h4 h5, h6 {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.heading-one {
  font-family: "Museo Sans W01_500";
  font-size: 28px;
  font-size: 1.75rem;
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  @media screen and (min-width: 714px) {
    .heading-one {
      font-size: 36px;
      font-size: 2.25rem; } }
  .homepage-header .heading-one {
    font-family: "Museo Sans W01_300"; }

h1,
.h1 {
  font-family: "Museo Sans W01_500";
  font-size: 28px;
  font-size: 1.75rem;
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  color: #00386C; }
  @media screen and (min-width: 714px) {
    h1,
    .h1 {
      font-size: 36px;
      font-size: 2.25rem; } }
  .homepage-header h1, .homepage-header
  .h1 {
    font-family: "Museo Sans W01_300"; }

.heading-two {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  font-family: "Museo Sans W01_500";
  font-size: 30px;
  font-size: 1.875rem; }

h2,
.h2 {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  font-family: "Museo Sans W01_500";
  font-size: 30px;
  font-size: 1.875rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  color: #00386C; }

.heading-three {
  font-family: "Museo Sans W01_500";
  font-size: 24px;
  font-size: 1.5rem; }

h3, h4, h5, h6 {
  font-family: "Museo Sans W01_500";
  font-size: 24px;
  font-size: 1.5rem;
  color: #00386C;
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }
  h3.underline, h4.underline, h5.underline, h6.underline {
    padding-bottom: 16px;
    padding-bottom: 1rem;
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  h3 a, h4 a, h5 a, h6 a {
    color: #00386C; }

.heading-min, .pullout-box .heading-min {
  font-family: "Museo Sans W01_500";
  font-size: 1em;
  border: none;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  padding: 0; }

hr, .hr, h3.underline, h4.underline, h5.underline, h6.underline, .pullout-box h1, .pullout-box h3, .pullout-box h4, .pullout-box h5, .pullout-box h6, .pullout-box h2, .section-links > li:after {
  border: none;
  border-bottom: 2px solid #EEE;
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  hr.invisible, .hr.invisible, h3.invisible.underline, h4.invisible.underline, h5.invisible.underline, h6.invisible.underline, .pullout-box h1.invisible, .pullout-box h3.invisible, .pullout-box h4.invisible, .pullout-box h5.invisible, .pullout-box h6.invisible, .pullout-box h2.invisible, .section-links > li.invisible:after {
    visibility: hidden; }
  hr.hr-thin, .hr.hr-thin, h3.hr-thin.underline, h4.hr-thin.underline, h5.hr-thin.underline, h6.hr-thin.underline, .pullout-box h1.hr-thin, .pullout-box h3.hr-thin, .pullout-box h4.hr-thin, .pullout-box h5.hr-thin, .pullout-box h6.hr-thin, .pullout-box h2.hr-thin, .section-links > li.hr-thin:after {
    border-width: 1px; }

strong, b {
  font-family: "Museo Sans W01_700"; }

p, li, th, td {
  font-size: 16px;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Museo Sans W01_300"; }

.breadcrumb {
  color: #ffffff;
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }
  .breadcrumb li {
    display: inline;
    margin-right: 0.4em; }
  .breadcrumb li {
    margin-right: 0; }
  .breadcrumb li:last-child:after {
    display: none; }
  .breadcrumb li:after {
    content: ' › '; }
  .breadcrumb a {
    color: #ffffff; }

ul#search-result-spec li h4 {
  margin-bottom: 0; }

ul#search-result-spec li .ex-sml {
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #7F9CB6; }

table thead {
  background-color: #00386C;
  color: #ffffff;
  font-family: "Museo Sans W01_500"; }

table th {
  text-align: left;
  font-family: "Museo Sans W01_500";
  color: #ffffff;
  background-color: #00386C; }

table th, table td {
  border-bottom: 1px solid #CCD8E2;
  padding: 0.8em; }

table td {
  color: #333333;
  font-family: "Museo Sans W01_300"; }

.councillor-party {
  font-size: 18px;
  font-size: 1.125rem;
  font-family: "Museo Sans W01_500";
  font-weight: normal;
  display: block;
  font-size: 18px;
  font-size: 1.125rem; }

.icon.twitter, a.twitter[target=_blank]:after {
  background-position: -2px -70px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 19px;
  display: inline-block; }

.icon.facebook, a.facebook[target=_blank]:after {
  background-position: -2px -116px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 21px;
  height: 21px;
  display: inline-block; }

.icon.youtube, a.youtube[target=_blank]:after {
  background-position: -2px -93px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 24px;
  height: 19px;
  display: inline-block; }

.icon.search, a.search[target=_blank]:after {
  background-position: -2px -50px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 3px; }

.glow {
  background: #FFF;
  border: 5px solid rgba(199, 223, 243, 0.23);
  border-radius: 3px; }

.bin_purchase_form {
  padding: 0em;
  background: #FFF;
  border: none !important;
  border-radius: 0px;
  margin-bottom: 0px;
  margin-bottom: 0em; }

input[type=text],
input[type=email],
input[type=number],
input[type=tel],
input[type=search],
input[type=url],
textarea {
  font-family: "Museo Sans W01_500";
  font-size: 15px;
  font-size: 0.9375rem;
  color: #00386C;
  padding: 1em 1.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none; }
  input[type=text]:focus,
  input[type=email]:focus,
  input[type=number]:focus,
  input[type=tel]:focus,
  input[type=search]:focus,
  input[type=url]:focus,
  textarea:focus {
    outline: none; }
  .form-wrap input[type=text], .form-wrap
  input[type=email], .form-wrap
  input[type=number], .form-wrap
  input[type=tel], .form-wrap
  input[type=search], .form-wrap
  input[type=url], .form-wrap
  textarea {
    border: none; }
  .form-row input[type=text], .unit input[type=text], .form-row
  input[type=email], .unit
  input[type=email], .form-row
  input[type=number], .unit
  input[type=number], .form-row
  input[type=tel], .unit
  input[type=tel], .form-row
  input[type=search], .unit
  input[type=search], .form-row
  input[type=url], .unit
  input[type=url], .form-row
  textarea, .unit
  textarea {
    padding: 1em;
    display: block;
    width: 100%; }
    .form-row input[type=text]:focus, .unit input[type=text]:focus, .form-row
    input[type=email]:focus, .unit
    input[type=email]:focus, .form-row
    input[type=number]:focus, .unit
    input[type=number]:focus, .form-row
    input[type=tel]:focus, .unit
    input[type=tel]:focus, .form-row
    input[type=search]:focus, .unit
    input[type=search]:focus, .form-row
    input[type=url]:focus, .unit
    input[type=url]:focus, .form-row
    textarea:focus, .unit
    textarea:focus {
      border-color: rgba(90, 161, 220, 0.23); }

input[type=submit] {
  text-transform: uppercase;
  min-width: 125px; }

@media screen and (min-width: 880px) {
  .ch-header {
    width: 55%; } }

.btn, .page-content form input[type=submit] {
  font-family: "Museo Sans W01_500";
  font-size: 14px;
  font-size: 0.875rem;
  color: #FFF;
  background: #007DC5;
  border: none;
  padding: 1em 1.2em;
  border-radius: 2px;
  cursor: pointer;
  border-radius: 56px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  -webkit-appearance: none; }
  @media screen and (min-width: 768px) {
    .btn, .page-content form input[type=submit] {
      font-size: 16px;
      font-size: 1rem; } }
  .btn.btn-full, .page-content form input.btn-full[type=submit] {
    width: 100%;
    display: block;
    text-align: center; }
  .btn.btn-small, .page-content form input.btn-small[type=submit] {
    padding: 0.8em 1.2em; }
  .btn:hover, .page-content form input:hover[type=submit] {
    background: #006aa6; }
  .btn:focus, .page-content form input:focus[type=submit] {
    background-color: #ffbf47;
    border-color: #ffbf47;
    outline: 3px solid #ffbf47; }
  .dark .btn, .dark .page-content form input[type=submit], .page-content form .dark input[type=submit] {
    background: #fff;
    color: #007DC5;
    border: 2px solid #fff; }
    .dark .btn:hover, .dark .page-content form input:hover[type=submit], .page-content form .dark input:hover[type=submit] {
      color: #fff;
      background: none; }

a[target=_blank]:after {
  content: '';
  background-position: -2px -33px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-left: 0.1em; }
  .downloads-list a[target=_blank]:after {
    display: none; }

span.btn a[target=_blank] {
  color: #fff;
  text-decoration: none; }
  span.btn a[target=_blank]:after {
    background-position: -2px -16px;
    background-repeat: no-repeat;
    overflow: hidden;
    display: block;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-left: 0.6em; }

.form-wrap {
  background: #FFF;
  border: 5px solid rgba(199, 223, 243, 0.23);
  border-radius: 3px;
  padding: 0.3em; }

.autocomplete-suggestions {
  display: none; }
  @media screen and (min-width: 768px) {
    .autocomplete-suggestions {
      display: block;
      width: 100% !important;
      background: white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      max-width: 370px;
      overflow: hidden;
      overflow-y: scroll;
      margin-top: -25px;
      margin-left: -5px;
      padding-top: 25px; } }

.autocomplete-suggestion {
  padding: 1em;
  color: #7F9CB6;
  border-bottom: solid 1px #E5EBF0;
  display: block; }
  .autocomplete-suggestion:last-of-type {
    border-bottom: none; }
  .autocomplete-suggestion.autocomplete-selected {
    background: rgba(199, 223, 243, 0.23);
    cursor: pointer;
    border-radius: 2px;
    color: #007DC5; }

.bd-home .autocomplete-suggestions {
  max-width: 580px !important;
  margin-left: 0px; }

.search-form .form-wrap {
  width: 370px; }
  .search-form .form-wrap input[type=text],
  .search-form .form-wrap input[type=search] {
    text-align: left;
    width: 100%;
    border-radius: 55px;
    height: 50px;
    padding: 5px 5px 5px 30px !important; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7F9CB6; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #7F9CB6; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #7F9CB6; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #7F9CB6; }

.getting-things-done.col-3 {
  max-width: none; }
  @media screen and (min-width: 410px) {
    .getting-things-done.col-3 ul {
      column-count: 2; } }
  @media screen and (min-width: 600px) {
    .getting-things-done.col-3 ul {
      column-count: 3; } }
  @media screen and (min-width: 1050px) {
    .getting-things-done.col-3 {
      max-width: 33%;
      margin-top: -1.6em; }
      .getting-things-done.col-3 ul {
        column-count: auto; } }

@media screen and (min-width: 1050px) {
  .upcoming-events.col-3 {
    max-width: 33%; } }

.pullout-box {
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  .bd-publications .pullout-box hr, .bd-publications .pullout-box p {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .bd-publications .pullout-box .heading-min {
    margin-bottom: 10px;
    margin-bottom: 0.63rem; }
  .pullout-box .heading-min {
    padding-top: 20px; }
  .pullout-box ul {
    margin-bottom: 0; }
    .bd-home .pullout-box ul {
      margin-bottom: 20px;
      margin-bottom: 1.25rem; }
  .pullout-box h1, .pullout-box h3, .pullout-box h4, .pullout-box h5, .pullout-box h6 {
    font-family: "Museo Sans W01_500";
    font-size: 24px;
    font-size: 1.5rem;
    padding-bottom: 16px;
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .pullout-box h2 {
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
    font-family: "Museo Sans W01_500";
    font-size: 30px;
    font-size: 1.875rem;
    padding-bottom: 23px;
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .pullout-box ul {
    list-style-type: none; }
    .pullout-box ul li {
      margin-bottom: 10px;
      margin-bottom: 0.63rem; }

.pullout-box {
  -webkit-animation: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  animation: all 0.4s cubic-bezier(0.77, 0, 0.175, 1); }
  @media screen and (min-width: 1045px) {
    .pullout-box {
      padding-top: 0.8em;
      padding-top: 1em; } }
  .pullout-box--header {
    background-color: #00386C;
    padding: 0px;
    border-bottom: solid 1px transparent;
    position: relative; }
    @media screen and (min-width: 1045px) {
      .pullout-box--header:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        background: url(../images/icons/pullout-box-left-arrow.svg);
        background-repeat: no-repeat;
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -6px; } }
    .pullout-box--header .h2 {
      display: block;
      width: 100%;
      text-decoration: none;
      color: #FFFFFF !important;
      font-size: 1em;
      line-height: 24px;
      margin-bottom: 0px;
      margin-left: 0px !important;
      padding: 11px 30px;
      cursor: pointer;
      border: none;
      position: relative; }
      @media screen and (max-width: 1045px) {
        .pullout-box--header .h2.mobile-hide {
          display: none;
          visibility: hidden;
          padding: 0 !important;
          margin: 0px; } }
      .pullout-box--header .h2 a {
        text-decoration: none !important;
        color: #FFFFFF !important; }
    .pullout-box--header .current-page {
      display: none;
      position: relative; }
      @media screen and (max-width: 1045px) {
        .pullout-box--header .current-page {
          background-color: #007DC5;
          display: block;
          width: 100%;
          text-decoration: none;
          color: #FFFFFF !important;
          font-size: 18px;
          line-height: 1.1;
          margin-bottom: 0px;
          padding: 17px 20px;
          cursor: pointer;
          border: none;
          margin-top: -20px; } }
      @media screen and (max-width: 1045px) {
        .pullout-box--header .current-page:after {
          content: '';
          width: 12px;
          height: 15px;
          background: url(../images/icons/current-page-arrow.svg);
          background-size: auto;
          background-position: -5px 0;
          background-repeat: no-repeat;
          position: absolute;
          right: 20px;
          top: 50%;
          margin-top: -7px; } }
  .pullout-box--inner .sidebar-nav {
    display: block; }
    .pullout-box--inner .sidebar-nav li {
      margin: 0px;
      border-bottom: solid 1px #CCD8E2; }
      .pullout-box--inner .sidebar-nav li a {
        color: #00386C;
        text-decoration: none;
        display: block;
        width: 100%;
        padding: 11px 20px;
        position: relative;
        cursor: pointer;
        font-size: 16px;
        font-size: 1em;
        font-family: "Museo Sans W01_500"; }
        @media screen and (min-width: 768px) {
          .pullout-box--inner .sidebar-nav li a {
            padding: 11px 30px; } }
        @media screen and (min-width: 1045px) {
          .pullout-box--inner .sidebar-nav li a:after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url(../images/icons/pullout-box-right-arrow.svg);
            background-repeat: no-repeat;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -6px; } }
        .pullout-box--inner .sidebar-nav li a:hover, .pullout-box--inner .sidebar-nav li a.current {
          background-color: #F2F8FC; }
        .pullout-box--inner .sidebar-nav li a.active {
          color: #ffffff;
          background-color: #007DC5; }
      .pullout-box--inner .sidebar-nav li.mobile-back {
        display: none; }
        @media screen and (max-width: 1045px) {
          .pullout-box--inner .sidebar-nav li.mobile-back {
            background-color: #7F9CB6;
            display: block; }
            .pullout-box--inner .sidebar-nav li.mobile-back a {
              color: #ffffff;
              padding-left: 30px; }
              .pullout-box--inner .sidebar-nav li.mobile-back a:before {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background: url(../images/icons/pullout-box-left-arrow.svg);
                background-repeat: no-repeat;
                position: absolute;
                left: 15px;
                top: 50%;
                margin-top: -6px; }
              .pullout-box--inner .sidebar-nav li.mobile-back a:hover {
                background-color: #007DC5; } }
      .pullout-box--inner .sidebar-nav li ul {
        display: none !important; }
        .pullout-box--inner .sidebar-nav li ul li {
          margin: 0px;
          border-bottom: solid 1px #CCD8E2; }
          .pullout-box--inner .sidebar-nav li ul li:first-of-type {
            border-top: solid 1px #CCD8E2; }
          .pullout-box--inner .sidebar-nav li ul li:last-of-type {
            border-color: transparent; }
          .pullout-box--inner .sidebar-nav li ul li:hover, .pullout-box--inner .sidebar-nav li ul li.active, .pullout-box--inner .sidebar-nav li ul li.current {
            background-color: #F2F8FC; }
          .pullout-box--inner .sidebar-nav li ul li a {
            color: #00386C;
            text-decoration: none;
            display: block;
            width: 100%;
            padding: 11px 20px;
            position: relative;
            cursor: pointer;
            font-size: 14px;
            font-family: "Museo Sans W01_500"; }
            @media screen and (min-width: 768px) {
              .pullout-box--inner .sidebar-nav li ul li a {
                padding: 11px 30px; } }
            @media screen and (min-width: 1045px) {
              .pullout-box--inner .sidebar-nav li ul li a:after {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                background: url(../images/icons/pullout-box-right-arrow.svg);
                background-repeat: no-repeat;
                position: absolute;
                right: 10px;
                top: 50%;
                margin-top: -6px; } }
            .pullout-box--inner .sidebar-nav li ul li a:hover, .pullout-box--inner .sidebar-nav li ul li a.active, .pullout-box--inner .sidebar-nav li ul li a.current {
              color: #00386C !important;
              background-color: #F2F8FC; }
      .pullout-box--inner .sidebar-nav li.active {
        background-color: #F2F8FC; }
        .pullout-box--inner .sidebar-nav li.active ul {
          display: block !important; }
          .pullout-box--inner .sidebar-nav li.active ul li a:hover {
            text-decoration: underline; }
          .pullout-box--inner .sidebar-nav li.active ul li ul li a {
            padding-left: 50px; }
  @media screen and (max-width: 1045px) {
    .pullout-box--inner .sidebar-nav {
      display: none; } }

.pullout-box.show-nav .sidebar-nav {
  display: block; }

@media screen and (max-width: 1045px) {
  .show-nav .pullout-box--header:after {
    content: '';
    background: url(../images/icons/current-page-arrow.svg);
    background-size: auto;
    background-position: 0px -17px;
    width: 14px;
    height: 12px;
    top: 50%;
    margin-top: -7px; } }

@media screen and (max-width: 1045px) {
  .pullout-box--header:after {
    content: '';
    background: url(../images/icons/current-page-arrow.svg);
    background-size: auto;
    background-position: 0px -17px;
    width: 14px;
    height: 12px;
    top: 50%;
    margin-top: -7px; } }

.section-links {
  color: #7F9CB6;
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  .section-links h2, .section-links h3, .section-links h4, .section-links h5, .section-links h6 {
    font-family: "Museo Sans W01_500";
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 10px;
    margin-bottom: 0.63rem; }
    .section-links h2 a, .section-links h3 a, .section-links h4 a, .section-links h5 a, .section-links h6 a {
      color: #00386C; }
  .section-links > li {
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
    position: relative; }
    @media screen and (min-width: 600px) {
      .section-links > li {
        min-height: 9em; } }
    .section-links > li:after {
      content: '';
      bottom: 0;
      left: 20px;
      right: 20px;
      position: absolute;
      border-width: 1px;
      margin-bottom: 0; }
  .section-links ul li {
    display: inline;
    margin-right: 0.4em; }
  .section-links p, .section-links ul {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }

.form-row {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.info-box {
  padding: 1.6em;
  background: rgba(199, 223, 243, 0.23);
  border: 5px solid rgba(195, 221, 242, 0.23);
  font-size: 19px;
  font-size: 1.1875rem;
  overflow: hidden;
  border-radius: 3px;
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  .info-box p {
    margin-bottom: 0; }

.page-content form input[type=text],
.page-content form input[type=email],
.page-content form input[type=number],
.page-content form input[type=tel],
.page-content form input[type=search],
.page-content form input[type=url],
.page-content form textarea {
  width: 100%;
  border: 1px solid #CCD8E2;
  color: #00386C; }
  .page-content form input[type=text]:focus,
  .page-content form input[type=email]:focus,
  .page-content form input[type=number]:focus,
  .page-content form input[type=tel]:focus,
  .page-content form input[type=search]:focus,
  .page-content form input[type=url]:focus,
  .page-content form textarea:focus {
    border-color: #007DC5;
    box-shadow: 0 0 0 3px rgba(0, 125, 197, 0.1); }

.page-content form h2 {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.page-content form label {
  font-family: "Museo Sans W01_700";
  font-size: 16px;
  font-size: 1rem;
  color: #00386C; }
  .page-content form label.error {
    color: #dc143c !important; }

.page-content form p {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  color: #7F9CB6; }
  .page-content form p.form-mandatory {
    color: crimson;
    padding: 10px; }

.page-content form input[type=submit] {
  font-size: 16px;
  font-size: 1rem;
  padding: 1em 2em; }

.error {
  color: #c01b1b;
  font-family: "Calibri W01 Regular_904604" !important;
  font-weight: normal;
  font-size: 16px !important;
  padding-top: 5px;
  display: block; }

.blue-box {
  background-color: #F2F8FC;
  padding: 20px;
  color: #00386C;
  line-height: 1.5;
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  @media screen and (min-width: 768px) {
    .blue-box {
      padding: 40px; } }
  .blue-box p {
    margin-bottom: 30px;
    color: #00386C;
    font-family: "Museo Sans W01_500";
    line-height: 1.5; }
    @media screen and (min-width: 768px) {
      .blue-box p {
        font-size: 20px; } }
    .blue-box p:last-of-type {
      margin-bottom: 0px; }

.downloads-list {
  margin: 0; }

.downloads-list--filesize {
  text-transform: uppercase;
  color: #7F9CB6; }

.disable {
  opacity: 0.4;
  pointer-events: none; }

.unit, .form-row {
  padding: 10px 10px 10px !important;
  margin-bottom: 10px;
  margin-bottom: 0.63rem; }
  footer .unit, footer .form-row {
    padding: 0 !important; }
  .unit label, .form-row label {
    display: block;
    margin-bottom: 10px;
    margin-bottom: 0.63rem; }
  .unit li label, .form-row li label {
    display: inline; }
  .unit select, .form-row select {
    width: 50%;
    display: block;
    font-size: 1.2em; }

.pagination a, .pagination strong {
  display: inline-block;
  text-decoration: none;
  background: #fff;
  padding: 0.2em 0.7em;
  border: 1px solid #CCD8E2; }

.pagination strong {
  background: none;
  font-family: "Calibri W01 Regular_904604"; }

.pagination a:hover {
  background: #007DC5;
  color: #fff;
  border-color: #007DC5; }

.list-atoz {
  list-style-type: none;
  margin-bottom: 40px;
  text-align: center; }
  @media screen and (min-width: 726px) {
    .list-atoz {
      text-align: left; } }
  @media screen and (max-width: 1045px) {
    .list-atoz {
      padding-top: 20px; } }
  .list-atoz li {
    display: inline; }
    .list-atoz li span, .list-atoz li a {
      padding: 0.2em 0.65em;
      display: inline-block;
      border: 1px solid #CCD8E2;
      color: #7F9CB6;
      margin-right: 0.3em;
      margin-bottom: 0.3em; }
    .list-atoz li a {
      color: #00386C;
      text-decoration: none;
      border-color: #7F9CB6 !important; }
      .list-atoz li a:hover {
        background: #7F9CB6;
        color: #fff; }

.list-councillors {
  margin-bottom: 75px;
  margin-bottom: 4.69rem; }
  .list-councillors h3 {
    margin-bottom: 5px;
    margin-bottom: 0.31rem; }

.list-councillors--councillor {
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  .list-councillors--councillor img {
    background: #FFF;
    border: 5px solid rgba(199, 223, 243, 0.23);
    border-radius: 3px; }

.sitewide-bar {
  background: #fff;
  color: #846123;
  padding: 20px 20px 0;
  padding: 1rem 1rem 0;
  overflow: hidden;
  text-align: center; }
  .sitewide-bar h1, .sitewide-bar h2, .sitewide-bar h3, .sitewide-bar h4, .sitewide-bar h5, .sitewide-bar h6, .sitewide-bar p {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .sitewide-bar.red {
    background: #e8bcba;
    color: #a41e20; }
  .sitewide-bar.green {
    background: #d7f2cd;
    color: #496041; }
  .sitewide-bar.blue {
    background: #cae4f2;
    color: #4070a5; }
  .sitewide-bar.transparent {
    background: #ffffff;
    color: #00386c; }

.ff_composer .required_item {
  color: #dc143c !important; }

.ff_composer fieldset {
  margin-bottom: 30px; }

fieldset h4 {
  padding: 10px; }

.sample {
  color: #7F9CB6;
  display: block;
  padding-top: 3px; }

.unit.postcode-inline select {
  width: auto;
  display: inline; }

.unit.postcode-inline #txtPostcode2 {
  display: inline;
  background: #FFF;
  border: 1px solid #dedede;
  border-radius: 3px;
  padding: 0.5em 0.8em;
  font-family: 'Museo Sans W01_500';
  font-size: 15px;
  font-size: .9375rem; }

.bttn {
  font-family: "Museo Sans W01_500";
  font-size: 16px;
  font-size: 1rem;
  border: solid 1px;
  padding: 1em 1.2em;
  border-radius: 56px;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  display: inline-block !important;
  text-decoration: none; }
  .bttn--signup {
    border-color: #007DC5;
    color: #FFF;
    background: #007DC5;
    margin: 0 auto;
    padding: 15px 45px;
    text-transform: uppercase; }
  .bttn--primary {
    border-color: #007DC5;
    color: #FFF;
    background: #007DC5;
    margin: 0 auto;
    padding: 15px 45px;
    padding-left: 30px;
    text-decoration: none; }
    .bttn--primary:after {
      content: '';
      display: block;
      width: 12px;
      height: 15px;
      background: url(../images/icons/cta-arrow.svg);
      background-repeat: no-repeat;
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -7px; }
    .bttn--primary:hover {
      text-decoration: none !important;
      background: #006aa6; }
  .bttn--secondary {
    border-color: #FFF;
    color: #007DC5;
    background: #FFF; }
  .bttn--ghost {
    border-color: #7F9CB6;
    color: #7F9CB6;
    background: transparent;
    min-width: 160px; }
    .bttn--ghost:after {
      content: '';
      display: block;
      width: 12px;
      height: 15px;
      background: url(../images/icons/ghost-btn-arrow.svg);
      background-repeat: no-repeat;
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -7px; }
  .bttn--view-more {
    border-color: #007DC5;
    color: #FFF;
    background: #007DC5;
    margin: 0 auto;
    padding: 15px 45px; }
    .bttn--view-more:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: url(../images/icons/more-services.svg);
      background-repeat: no-repeat;
      position: absolute;
      right: 25px;
      top: 50%;
      margin-top: -5px; }
    .bttn--view-more:hover:after {
      content: '';
      background: url(../images/icons/more-services-blue.svg); }

.js-view-more,
.js-view-less {
  font-family: "Museo Sans W01_500";
  font-size: 16px;
  font-size: 1rem;
  border: solid 1px;
  padding: 1em 1.2em;
  border-radius: 56px;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  display: inline-block !important;
  text-decoration: none;
  border-color: #007DC5;
  color: #FFF;
  background: #007DC5;
  margin: 0 auto;
  padding: 15px 45px; }
  .js-view-more:after,
  .js-view-less:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: url(../images/icons/more-services.svg);
    background-repeat: no-repeat;
    position: absolute;
    right: 25px;
    top: 50%;
    margin-top: -5px; }
  .js-view-more:hover,
  .js-view-less:hover {
    background: #006aa6; }

.js-view-less:after {
  content: '';
  display: block;
  width: 10px;
  height: 2px;
  background: url(../images/icons/view-less.png);
  background-repeat: no-repeat;
  position: absolute;
  right: 25px;
  top: 50%;
  margin-top: -1px; }

header {
  padding: 1em 20px;
  background: #fff; }
  header .container {
    position: relative; }

.logo, .logo-small {
  vertical-align: middle;
  position: static;
  text-shadow: none;
  margin-right: 2em;
  display: inline-block;
  display: none; }
  .logo:after, .logo-small:after {
    display: none; }
  .logo span, .logo-small span {
    display: none; }

.form-search {
  text-align: left;
  width: 100%;
  max-width: 460px; }
  .form-search label {
    display: none; }
  .form-search input {
    display: inline-block; }
  .form-search input[type=text] {
    width: 63%; }
    @media screen and (min-width: 450px) {
      .form-search input[type=text] {
        width: 78%; } }
  .form-search .btn, .form-search .page-content form input[type=submit], .page-content form .form-search input[type=submit] {
    float: right; }

#mobile-menu form.form-search {
  width: 100% !important;
  max-width: 100% !important; }

#mobile-menu input[type=text] {
  width: 100% !important; }

.header-left, .header-middle, .header-right {
  display: inline-block;
  vertical-align: middle; }

.header-middle {
  width: 100%;
  clear: both;
  margin-top: 1.6em;
  display: none; }
  .header-middle form {
    margin: 0 auto; }
    @media screen and (min-width: 702px) {
      .header-middle form {
        margin: 0; } }
  @media screen and (min-width: 702px) {
    .header-middle {
      max-width: 403px;
      clear: none;
      margin-top: 0;
      display: inline-block !important; } }
  .header-middle .btn-search {
    float: right; }
  @media screen and (min-width: 990px) {
    .header-middle {
      clear: none;
      max-width: 495px;
      max-width: 475px; } }

@media screen and (max-width: 990px) {
  .header-right {
    display: block;
    margin-top: 1em;
    position: absolute;
    left: -20px;
    right: -20px;
    z-index: 999999; } }

@media screen and (min-width: 990px) {
  .header-right {
    margin-top: 0.9em;
    display: inline-block;
    text-align: right;
    float: right;
    position: relative; } }

.header-right ul li {
  display: block;
  border-bottom: solid 1px #406990; }

@media screen and (min-width: 990px) {
  .header-right ul {
    margin-bottom: 0; }
    .header-right ul li {
      margin-bottom: 10px;
      margin-bottom: 0.63rem; }
    .header-right ul li {
      display: inline;
      margin-right: 0.4em; }
    .header-right ul li {
      margin-bottom: 0;
      margin-right: 0; }
      .header-right ul li a {
        color: #00386C; }
        .header-right ul li a:hover {
          color: #007DC5; }
      .header-right ul li:last-child a {
        margin-right: 0; }
      .header-right ul li.utility-nav--social a {
        margin-left: 0.3em; } }

.list-social {
  margin-bottom: 0; }
  .list-social a:after {
    border: none; }

.nav-main-wrap {
  display: block;
  position: absolute;
  top: 0px;
  right: 20px; }
  .nav-main-wrap a {
    text-decoration: none; }
  .nav-main-wrap ul {
    margin-bottom: 0; }

.nav-main {
  display: block;
  font-size: 19px;
  font-size: 1.1875rem; }
  .nav-main ul {
    display: block; }
  .nav-main li {
    display: inline-block;
    font-family: "Museo Sans W01_500";
    font-size: 18px;
    font-size: 1.125rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    .nav-main li a {
      display: inline-block;
      color: #00386C;
      padding: 0.9em 0.8em;
      position: static;
      text-shadow: none;
      text-decoration: none; }
      .nav-main li a:after {
        display: none; }
  .nav-main .nav-main--tasks li a {
    padding: 0.9em 0.6em; }

.nav-main--tasks {
  background: #110660;
  float: right; }

.utility-nav li, .list-social li, .list-contact li {
  display: inline; }

.utility-nav {
  margin-bottom: 8px; }

.icon.menu, a.menu[target=_blank]:after {
  background-position: -2px -2px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 14px;
  height: 10px;
  float: right; }

.nav-main__large {
  display: none; }
  @media screen and (min-width: 1278px) {
    .nav-main__large {
      display: block;
      overflow: hidden; } }
  .nav-main__large .first {
    display: none; }
    @media screen and (min-width: 1310px) {
      .nav-main__large .first {
        display: inline; } }

.nav-main__small {
  position: relative; }
  @media screen and (min-width: 1278px) {
    .nav-main__small {
      display: none; } }

.nav-main__small-menu {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer; }

.nav-main__small-menu--icon {
  background: #110660;
  padding: 1.28em;
  overflow: hidden;
  float: right; }

.nav-main__small-menu--links {
  z-index: 2;
  background: #110660;
  width: 10em;
  clear: both;
  display: none; }
  .nav-main__small-menu--links.active {
    display: block; }
  .nav-main__small-menu--links a:hover {
    background: none !important; }

.nav-main__small--wrap {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-right: 4em; }

.nav-main__small-menu--links li {
  display: block; }

.btn-search {
  float: right;
  margin-top: 10px; }
  @media screen and (min-width: 990px) {
    .btn-search {
      display: none; } }

.js-skip-links {
  display: block;
  text-indent: -999999px;
  bottom: 0;
  right: 20px;
  position: absolute;
  width: 40px;
  height: 40px; }
  @media screen and (min-width: 990px) {
    .js-skip-links {
      display: none; } }
  .js-skip-links--search {
    background-image: url(../images/icons/mobile-search.svg);
    background-repeat: no-repeat;
    background-position: center center;
    right: 70px; }
  .js-skip-links--menu {
    background-image: url(../images/icons/mobile-menu.svg);
    background-repeat: no-repeat;
    background-position: center center; }

@media screen and (max-width: 990px) {
  #mobile-menu,
  #mobile-search {
    background-color: #00386C;
    display: none;
    width: 100%;
    z-index: 99999; }
    #mobile-menu:before,
    #mobile-search:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #00386C;
      position: absolute;
      top: -10px;
      display: block;
      z-index: 99999; }
    #mobile-menu.active,
    #mobile-search.active {
      display: block;
      height: 1000px; }
    #mobile-menu .form-search,
    #mobile-search .form-search {
      width: 100%;
      max-width: 100%; }
  #mobile-menu a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 15px 20px; }
  #mobile-menu:before {
    right: 50px; }
  #mobile-search {
    padding: 20px;
    overflow: hidden; }
    #mobile-search:before {
      right: 95px; }
    #mobile-search .search-form {
      display: inline-block;
      width: 100%;
      height: auto; }
      #mobile-search .search-form .form-wrap {
        border: none;
        border-radius: 55px;
        text-align: left;
        position: relative;
        width: 100%; }
        #mobile-search .search-form .form-wrap input[type=text],
        #mobile-search .search-form .form-wrap input[type=search] {
          text-align: left;
          width: 100%;
          border-radius: 55px;
          height: 50px; }
        #mobile-search .search-form .form-wrap .btn, #mobile-search .search-form .form-wrap .page-content form input[type=submit], .page-content form #mobile-search .search-form .form-wrap input[type=submit] {
          background: #007DC5 url(../images/icons/Shape.svg) no-repeat;
          background-position: center center;
          border-radius: 100%;
          width: 50px;
          height: 50px;
          padding: 0px;
          position: absolute;
          right: 5px; }
        #mobile-search .search-form .form-wrap input[type=text],
        #mobile-search .search-form .form-wrap input[type=search],
        #mobile-search .search-form .form-wrap input[type=submit], #mobile-search .search-form .form-wrap .btn, #mobile-search .search-form .form-wrap .page-content form input[type=submit], .page-content form #mobile-search .search-form .form-wrap input[type=submit] {
          display: inline-block;
          vertical-align: middle; } }

@media screen and (min-width: 990px) {
  #mobile-search {
    display: none;
    visibility: hidden; }
  #mobile-menu li {
    display: inline-block;
    font-family: "Museo Sans W01_500";
    font-size: 18px;
    font-size: 1.125rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none; }
    #mobile-menu li a {
      display: inline-block;
      color: #00386C;
      padding: 0.9em 0.8em;
      position: static;
      text-shadow: none;
      text-decoration: none; }
      #mobile-menu li a:after {
        display: none; } }

#nav-sub .first {
  display: none; }

@media screen and (min-width: 1130px) {
  #nav-sub .first {
    display: inline-block;
    *display: inline;
    *zoom: 1; } }

/* Preload images */
body:after {
  content: url(../images/close.png) url(../images/loading.gif) url(../images/prev.png) url(../images/next.png);
  display: none; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-container {
  padding: 4px; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

:root {
  --cky-font-base:             "Museo Sans W01_300";
  --cky-font-bold:             "Museo Sans W01_500";
  --cky-title-font:            "Museo Sans W01_700";
  --cky-title-size:            24px;
  --cky-title-size-mob:        20px;
  --cky-paragraph-size:        15px;
  --cky-paragraph-lh:          24px;
  --cky-padding-box-mob:       30px 0;
  --cky-padding-box:           30px;
  --cky-border-radius-box:     2px;
  --cky-border-radius-button:  5px;
  --cky-brand-primary:         #00396C;
  --cky-brand-background:      #FFF;
  --cky-brand-heading:         #00396C;
  --cky-brand-text:            #000;
  --cky-brand-link:            var(--cky-brand-primary);
  --cky-brand-success:         var(--cky-brand-primary);
  --cky-brand-warning:         #999999;
  --cky-revisit-bg:            var(--cky-brand-primary);
  --cky-btn-weight:            bold;
  --cky-btn-accept-bg:         var(--cky-brand-primary);
  --cky-btn-reject-bg:         #FFF;
  --cky-btn-customize-bg:      #FFF;
  --cky-btn-accept-border:     2px solid var(--cky-brand-primary);
  --cky-btn-reject-border:     2px solid var(--cky-brand-primary);
  --cky-btn-customize-border:  2px solid var(--cky-brand-primary);
  --cky-btn-pref-border:       2px solid var(--cky-brand-primary);
  --cky-btn-accept-color:      #FFF;
  --cky-btn-reject-color:      var(--cky-brand-primary);
  --cky-btn-customize-color:   var(--cky-brand-primary);
  --cky-btn-pref-color:        var(--cky-brand-primary);
  --cky-checkbox-bg:           #000;
  --cky-checkbox-checked-bg:   var(--cky-brand-primary);
  --cky-checkbox-border:       1px solid #000;
  --cky-table-th-bg:           var(--cky-brand-primary);
  --cky-table-th-padding:      10px;
  --cky-table-td-padding:      10px;
  --cky-table-th-border:       none;
  --cky-table-td-border:       none;
  --cky-table-th-size:         16px;
  --cky-table-td-size:         16px;
  --cky-table-th-color:        #FFF;
  --cky-table-td-color:        var(--cky-brand-text);
  --cky-table-bg-odd:          #F3F3F3;
  --cky-table-bg-even:         #FFF; }

.cky-btn-revisit-wrapper {
  background-color: var(--cky-revisit-bg) !important; }

.cky-consent-bar {
  padding: var(--cky-padding-box) !important;
  background-color: var(--cky-brand-background) !important;
  border-radius: var(--cky-border-radius-box) !important;
  border-color: var(--cky-brand-background) !important; }
  @media (max-width: 440px) {
    .cky-consent-bar {
      padding: var(--cky-padding-box-mob) !important; } }

.cky-title {
  font-size: var(--cky-title-size) !important;
  font-family: var(--cky-title-font) !important;
  color: var(--cky-brand-heading) !important; }
  @media (max-width: 440px) {
    .cky-title {
      font-size: var(--cky-title-size-mob) !important; } }

.cky-notice-des p {
  line-height: var(--cky-paragraph-lh);
  color: var(--cky-brand-text) !important;
  font-size: var(--cky-paragraph-size); }

.cky-notice-des p a {
  font-family: var(--cky-font-bold) !important;
  color: var(--cky-brand-link) !important;
  font-weight: var(--cky-btn-weight) !important; }

@media (max-width: 440px) {
  .cky-notice-btn-wrapper {
    flex-direction: row !important; } }

.cky-btn {
  font-family: var(--cky-font-bold) !important;
  border-radius: var(--cky-border-radius-button) !important;
  font-weight: bold !important; }

.cky-btn-accept {
  background-color: var(--cky-btn-accept-bg) !important;
  border: var(--cky-btn-accept-border) !important;
  color: var(--cky-btn-accept-color) !important; }

.cky-btn-reject {
  background-color: var(--cky-btn-reject-bg) !important;
  border: var(--cky-btn-reject-border) !important;
  color: var(--cky-btn-reject-color) !important; }
  @media (max-width: 440px) {
    .cky-btn-reject {
      flex: 0 1 calc(50% - 5px) !important;
      margin-left: 5px !important; } }

.cky-btn-customize {
  background-color: var(--cky-btn-customize-bg) !important;
  border: var(--cky-btn-customize-border) !important;
  color: var(--cky-btn-customize-color) !important; }
  @media (max-width: 440px) {
    .cky-btn-customize {
      flex: 0 1 calc(50% - 5px) !important;
      margin-right: 5px !important; } }

.cky-btn-preferences {
  border: var(--cky-btn-pref-border) !important;
  color: var(--cky-btn-pref-color) !important; }

.cky-preference-center {
  background-color: var(--cky-brand-background) !important;
  border-color: var(--cky-brand-text) !important;
  border-radius: var(--cky-border-radius-box) !important; }

.cky-footer-shadow {
  display: none !important; }

.cky-preference-title {
  color: var(--cky-brand-heading) !important; }

.cky-preference-content-wrapper {
  color: var(--cky-brand-text) !important; }

.cky-show-desc-btn {
  color: var(--cky-brand-link) !important;
  text-decoration: underline !important; }

.cky-always-active {
  color: var(--cky-brand-success) !important; }

.cky-accordion-btn {
  color: var(--cky-brand-heading) !important; }

.cky-accordion-header-des {
  color: var(--cky-brand-text) !important; }

.cky-chevron-right {
  color: var(--cky-brand-link) !important; }

.cky-audit-table {
  background-color: var(--cky-brand-background) !important; }

.cky-cookie-des-table {
  color: var(--cky-brand-text); }

.cky-switch input[type="checkbox"] {
  background-color: var(--cky-checkbox-bg) !important;
  border: var(--cky-checkbox-border) !important; }

.cky-switch input[type="checkbox"] {
  border: 1px solid var(--cky-brand-text) !important; }

.cky-switch input[type="checkbox"]::before {
  transform: translateX(-1px) translateY(1px) !important; }

.cky-switch input[type="checkbox"]:checked {
  background-color: var(--cky-checkbox-checked-bg) !important;
  border-color: var(--cky-checkbox-checked-bg) !important; }

.cky-switch input[type="checkbox"]:checked::before {
  transform: translateX(19px) translateY(1px) !important; }

[data-cky-tag="detail-powered-by"] {
  background-color: transparent !important; }

.cky-cookie-audit-table th {
  padding: var(--cky-table-th-padding) !important;
  font-size: var(--cky-table-th-size) !important;
  background-color: var(--cky-table-th-bg) !important;
  color: var(--cky-table-th-color) !important;
  border: var(--cky-table-th-border) !important; }

.cky-cookie-audit-table td {
  padding: var(--cky-table-td-padding) !important;
  font-size: var(--cky-table-td-size) !important;
  color: var(--cky-table-td-color) !important;
  border: var(--cky-table-td-border) !important; }

.cky-cookie-audit-table tr:nth-child(2n + 1) td {
  background-color: var(--cky-table-bg-odd) !important; }

.cky-cookie-audit-table tr:nth-child(2n) td {
  background-color: var(--cky-table-bg-even) !important; }

* {
  box-sizing: border-box; }

body {
  background-color: #FFFFFF;
  font-family: "Calibri W01 Regular_904604";
  line-height: 1.5;
  color: #333333; }

.global-container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px; }
  .global-container.no-padding {
    padding: 0px; }

.footer-wrap {
  padding: 0 20px; }

a {
  color: #007DC5;
  text-decoration: underline;
  position: relative; }
  a:hover {
    text-decoration: none; }
    a:hover:focus {
      background-color: none;
      outline: none; }

.homepage-header-2 {
  position: relative;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2.6em 1em;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }
  @media screen and (max-width: 736px) {
    .homepage-header-2 {
      margin-bottom: 0px !important; } }
  @media screen and (min-width: 426px) {
    .homepage-header-2 {
      padding: 2.6em; } }
  @media screen and (min-width: 687px) {
    .homepage-header-2 {
      padding-top: 45px;
      padding-bottom: 45px;
      height: auto;
      min-height: 25em;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .homepage-header-2 .homepage-header--mask {
    opacity: 0.84;
    background: rgba(15, 66, 130, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; }
  .homepage-header-2 form {
    flex: none;
    width: 100%;
    max-width: 580px;
    text-align: center;
    position: relative; }
    .homepage-header-2 form input[type='text'] {
      width: 100%;
      border-radius: 55px;
      height: 100%; }
      @media screen and (min-width: 713px) {
        .homepage-header-2 form input[type='text'] {
          font-size: 17px;
          font-size: 1.0625rem; } }
    .homepage-header-2 form input[type='submit'],
    .homepage-header-2 form button {
      position: absolute;
      right: 5px;
      top: 5px; }
      @media screen and (min-width: 713px) {
        .homepage-header-2 form input[type='submit'],
        .homepage-header-2 form button {
          font-size: 17px;
          font-size: 1.0625rem; } }
    .homepage-header-2 form .btn, .homepage-header-2 .page-content form input[type=submit], .page-content .homepage-header-2 form input[type=submit] {
      background: #007DC5 url(../images/icons/Shape.svg) no-repeat;
      background-position: center center;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      padding: 0px; }
    .homepage-header-2 form h1, .homepage-header-2 form p {
      color: #fff;
      margin-bottom: 20px; }
    .homepage-header-2 form p {
      font-family: "Museo Sans W01_300";
      font-size: 21px;
      font-size: 1.3125rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 45px 0 20px 0; }
    .homepage-header-2 form .form-wrap {
      border: none;
      border-radius: 55px;
      text-align: left;
      height: 60px;
      position: relative;
      padding: 0px;
      z-index: 99988; }
  .homepage-header-2 h1 {
    font-family: "Museo Sans W01_500";
    font-size: 25px;
    font-size: 1.5625rem; }
    @media screen and (min-width: 768px) {
      .homepage-header-2 h1 {
        font-size: 36px;
        font-size: 2.25rem; } }

.notification-bar {
  background: #007DC5;
  color: #fff;
  text-align: center;
  padding: 1em;
  border-radius: 3px;
  margin-bottom: 60px;
  margin-bottom: 3.75rem; }
  @media screen and (min-width: 600px) {
    .notification-bar {
      margin: 0 20px;
      margin-bottom: 60px;
      margin-bottom: 3.75rem; } }
  .notification-bar p {
    margin-bottom: 0; }

.news-pod .news-pod--image {
  margin-bottom: 10px;
  margin-bottom: 0.63rem; }
  .news-pod .news-pod--image img {
    width: 100%;
    display: block; }

.news-pod h4 {
  margin-bottom: 5px;
  margin-bottom: 0.31rem; }

.small, .meta {
  color: #7F9CB6;
  font-size: 17px;
  font-size: 1.0625rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.meta {
  color: #7F9CB6;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.small {
  color: #fff; }

footer {
  padding: 2.6em 0;
  background: #00386C; }
  footer .col-4 {
    vertical-align: middle;
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  footer hr {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  footer .logo {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  footer .list-social {
    margin-bottom: 30px; }
    footer .list-social li {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin-right: 10px; }
    footer .list-social li a {
      text-align: none;
      display: block; }
      footer .list-social li a:focus {
        outline: 2px solid #007dc5; }

.footer--links {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .footer--links ul {
    list-style-type: none; }
    .footer--links ul li {
      font-family: "Museo Sans W01_500";
      font-size: 24px;
      font-size: 1.5rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-bottom: 10px;
      margin-bottom: 0.63rem; }
      .footer--links ul li a {
        text-decoration: none;
        color: #fff; }
        .footer--links ul li a:hover {
          text-decoration: underline; }

.footer--mailing-list p {
  color: #7F9CB6;
  font-size: 17px;
  font-size: 1.0625rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 10px;
  margin-bottom: 0.63rem;
  color: #fff;
  margin-bottom: 30px; }

.footer--mailing-list .h3 {
  color: #fff;
  font-size: 14px; }
  @media screen and (min-width: 768px) {
    .footer--mailing-list .h3 {
      font-size: 24px; } }

@media screen and (max-width: 600px) {
  .footer--mailing-list .btn, .footer--mailing-list .page-content form input[type=submit], .page-content form .footer--mailing-list input[type=submit] {
    width: 100% !important; } }

.footer--contact,
.footer--links,
.footer--mailing-list {
  width: 100%;
  display: inline-block;
  vertical-align: top; }
  @media screen and (min-width: 600px) {
    .footer--contact,
    .footer--links,
    .footer--mailing-list {
      padding: 0 20px; } }
  @media screen and (min-width: 768px) {
    .footer--contact,
    .footer--links,
    .footer--mailing-list {
      width: 33.333%; } }

.footer--gutter {
  padding: 10px 0px; }
  @media screen and (min-width: 600px) {
    .footer--gutter {
      padding: 10px 20px; } }

.category-header, .page-header {
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  max-height: 16em;
  overflow: hidden;
  position: relative;
  padding: 20px;
  clear: both; }
  .category-header img, .page-header img {
    width: 100%;
    display: block; }
  .category-header h1, .page-header h1 {
    margin-bottom: 0; }
  .category-header .container:after, .page-header .container:after {
    content: '';
    display: block;
    padding-top: 1.6em;
    border-bottom: 2px solid #CCD8E2; }
  .category-header.background-image h1, .page-header.background-image h1 {
    position: absolute;
    left: 20px;
    bottom: 0;
    color: #fff;
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
    @media screen and (min-width: 600px) {
      .category-header.background-image h1, .page-header.background-image h1 {
        left: 40px; } }
  .category-header.background-image ul, .page-header.background-image ul {
    position: absolute;
    bottom: -20px;
    color: #fff; }
    @media screen and (min-width: 600px) {
      .category-header.background-image ul, .page-header.background-image ul {
        left: 40px; } }
    .category-header.background-image ul a, .page-header.background-image ul a {
      color: #fff; }
  .category-header.background-image .container, .page-header.background-image .container {
    position: absolute;
    bottom: 0.6em;
    left: 0;
    right: 0;
    padding: 0 20px;
    border: none; }
    @media screen and (min-width: 600px) {
      .category-header.background-image .container, .page-header.background-image .container {
        bottom: 1.6em;
        margin-top: -100px; } }
    .category-header.background-image .container:after, .page-header.background-image .container:after {
      display: none; }
  @media screen and (min-width: 600px) {
    .category-header .container, .page-header .container {
      position: relative; } }

.category-header {
  background: #00386C;
  color: #ffffff; }
  .category-header h1 {
    color: #ffffff; }
  .category-header .container:after {
    content: '';
    display: none;
    padding-top: 0;
    border-bottom: none; }
  @media screen and (max-width: 1045px) {
    .category-header {
      margin-bottom: 0px; } }

.page-header {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }
  @media screen and (min-width: 600px) {
    .page-header .container {
      position: relative;
      padding: 2.6em; } }

.page-content {
  margin-bottom: 120px;
  margin-bottom: 7.5rem; }
  .page-content ul, .page-content ol {
    margin-left: 1em; }
  .page-content .sidebar ul {
    margin-left: 0; }
  .page-content .sidebar .pullout-box {
    padding-left: 0px; }

.sidebar {
  width: 100%;
  max-width: 100%;
  height: auto; }
  @media screen and (min-width: 1045px) {
    .sidebar {
      width: 33%; } }

.js-cookie-bar {
  background: #3E75A3;
  color: #fff;
  padding: 1.6em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999; }
  .js-cookie-bar p {
    margin-bottom: 30px;
    margin-bottom: 1.875rem; }
    .js-cookie-bar p:last-of-type {
      margin-bottom: 20px;
      margin-bottom: 1.25rem; }
    @media screen and (min-width: 970px) {
      .js-cookie-bar p {
        margin-bottom: 0; }
        .js-cookie-bar p:last-of-type {
          margin-bottom: 0; } }
  .js-cookie-bar a {
    font-size: 16px;
    font-size: 1em;
    font-family: "Museo Sans W01_500";
    color: #fff; }
  .js-cookie-bar .js-cookie-bar-close {
    font-size: 14px; }
    @media screen and (min-width: 970px) {
      .js-cookie-bar .js-cookie-bar-close {
        font-size: 16px; } }

@media screen and (min-width: 970px) {
  .js-cookie-bar--left {
    display: inline-block;
    vertical-align: middle;
    width: 66.6%; }
  .js-cookie-bar--right {
    display: inline-block;
    vertical-align: middle;
    width: 33.3%;
    text-align: right; } }

.list-recent-publications {
  list-style-type: none; }
  .list-recent-publications.mini h4 {
    margin-bottom: 0; }
  .list-recent-publications.mini p {
    margin-bottom: 0; }
  .list-recent-publications.mini hr {
    margin-bottom: 20px;
    margin-bottom: 1.25rem; }
  .list-recent-publications li p.event-fix {
    margin-bottom: 0; }
  .list-recent-publications li .event-copy {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: top;
    width: 100%;
    margin-top: 2.81rem; }
    @media screen and (min-width: 970px) {
      .list-recent-publications li .event-copy {
        width: 66%;
        padding-right: 20px; } }
  .list-recent-publications li .event-image {
    display: none; }
    @media screen and (min-width: 970px) {
      .list-recent-publications li .event-image {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        vertical-align: top;
        width: 33%; }
        .list-recent-publications li .event-image img {
          width: 100%; } }

.publication--status,
.publication--dates {
  display: block;
  color: #7F9CB6;
  font-size: 14px;
  padding: 0px;
  text-transform: capitalize;
  padding-right: 1em; }

.councillor-specific {
  display: block;
  background-color: #F2F8FC;
  padding: 30px; }

.councillor-photo {
  text-align: center;
  border: solid 1px #CCD8E2; }
  @media screen and (min-width: 700px) {
    .councillor-photo {
      margin-right: 30px; } }
  .councillor-photo img {
    display: block;
    width: 100%;
    max-width: 180px;
    height: auto; }

.councillors-district {
  border-bottom: solid 1px #CCD8E2;
  padding-bottom: 15px; }

.councillor-photo,
.councillor-info,
.councillor-contact,
.councillor-committee {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top; }

.councillor-contact h2,
.councillor-contact h3,
.councillor-committee h2,
.councillor-committee h3 {
  margin-bottom: 20px; }

@media screen and (min-width: 700px) {
  .councillor-contact {
    padding-right: 30px; } }

.councillor-email {
  clear: both;
  display: block; }

.list-councillors--councillor .col-4 {
  width: 100%;
  text-align: center; }
  @media screen and (min-width: 670px) {
    .list-councillors--councillor .col-4 {
      max-width: 50%; } }
  @media screen and (min-width: 700px) {
    .list-councillors--councillor .col-4 {
      max-width: 25%;
      text-align: left; } }

.list-councillors h1 {
  color: #00386C; }

.list-councillors h2 {
  border-bottom: solid 1px #CCD8E2;
  padding-bottom: 20px;
  font-size: 24px;
  margin-left: 0px !important; }

.list-councillors h3 {
  font-size: 1em; }

.list-councillors a {
  text-decoration: none; }

.list-councillors p {
  color: #7F9CB6; }

.list-councillors img {
  border: solid 1px #CCD8E2;
  border-radius: 0; }

.container.atoz {
  padding: 0; }
  .container.atoz h2 {
    margin-left: 20px; }
    .container.atoz h2.no-margin-left {
      margin-left: 0px !important; }
  .container.atoz .pullout-box {
    padding-left: 0px; }

.container.no-padding {
  padding-left: 0px;
  padding-right: 0px; }

.post--image {
  margin-bottom: 30px;
  margin-bottom: 1.875rem; }

.news-item .col-3, .news-item .col-2-3 {
  vertical-align: middle; }
  .news-item .col-3 img, .news-item .col-2-3 img {
    display: block; }

.news-item.grid-row hr {
  margin-left: 20px;
  margin-right: 20px; }

#a_to_z {
  padding: 0 20px; }
  @media screen and (min-width: 768px) {
    #a_to_z {
      padding: 0px; } }
  #a_to_z h2 {
    margin-left: 0px; }
  #a_to_z .grid-row {
    margin-left: 0px;
    margin-right: 0px; }
  #a_to_z .col-3 {
    padding-left: 0px; }

.payment-methods {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  margin-top: 12px; }

#news-carousel a {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 150px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
  overflow: hidden;
  position: relative;
  vertical-align: top; }
  #news-carousel a img {
    display: block;
    position: relative;
    margin: 0;
    padding: 0; }

.arrow-down {
  background: url(../images/icons/arrow-down.svg) no-repeat;
  display: block;
  clear: both;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  cursor: pointer; }

@media screen and (min-width: 768px) {
  .mea_services,
  .council-services {
    margin-left: 0px;
    margin-right: 0px; } }

.mea_services .col-4,
.council-services .col-4 {
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  width: 25%;
  max-width: 25% !important; }
  @media screen and (min-width: 768px) {
    .mea_services .col-4,
    .council-services .col-4 {
      padding: 20px;
      max-width: 25% !important; } }
  .mea_services .col-4 a,
  .council-services .col-4 a {
    text-decoration: none;
    font-size: 13px;
    line-height: 20px; }
    @media screen and (min-width: 768px) {
      .mea_services .col-4 a,
      .council-services .col-4 a {
        font-size: 16px;
        line-height: 20px; } }
  .mea_services .col-4 h3,
  .council-services .col-4 h3 {
    display: block;
    font-family: "Museo Sans W01_700";
    color: #00386C;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px; }
    @media screen and (min-width: 768px) {
      .mea_services .col-4 h3,
      .council-services .col-4 h3 {
        font-size: 24px;
        color: #00386C;
        line-height: 30px; } }
    .mea_services .col-4 h3 a,
    .council-services .col-4 h3 a {
      text-decoration: none;
      font-size: 14px;
      line-height: 20px; }
      @media screen and (min-width: 768px) {
        .mea_services .col-4 h3 a,
        .council-services .col-4 h3 a {
          font-size: 24px;
          color: #00386C;
          line-height: 30px; } }
  @media screen and (min-width: 768px) {
    .mea_services .col-4:hover,
    .council-services .col-4:hover {
      background-color: #007DC5;
      color: #fff; }
      .mea_services .col-4:hover h3,
      .mea_services .col-4:hover .icon,
      .mea_services .col-4:hover a[target=_blank]:after,
      .mea_services .col-4:hover span,
      .mea_services .col-4:hover a,
      .council-services .col-4:hover h3,
      .council-services .col-4:hover .icon,
      .council-services .col-4:hover a[target=_blank]:after,
      .council-services .col-4:hover span,
      .council-services .col-4:hover a {
        color: #fff; }
      
      .mea_services .col-4:hover .icon,
      .mea_services .col-4:hover a[target=_blank]:after,
      .mea_services .col-4:hover .ui_icon,
      .council-services .col-4:hover .icon,
      .council-services .col-4:hover a[target=_blank]:after,
      .council-services .col-4:hover .ui_icon {
        color: #fff; }
      .mea_services .col-4:hover .service-icon img,
      .council-services .col-4:hover .service-icon img {
        display: none; }
        .mea_services .col-4:hover .service-icon img.white_icon,
        .council-services .col-4:hover .service-icon img.white_icon {
          display: block; }
      .mea_services .col-4:hover .white-icon,
      .council-services .col-4:hover .white-icon {
        display: inline-block; } }

@media screen and (min-width: 768px) {
  .mea_services .col-4,
  .council-services .col-4 {
    border-left: solid 1px #CCD8E2;
    padding-top: none; }
    .mea_services .col-4:nth-child(4n+1),
    .council-services .col-4:nth-child(4n+1) {
      border-left: none; }
    .mea_services .col-4:nth-child(1n+5),
    .council-services .col-4:nth-child(1n+5) {
      border-top: solid 1px #CCD8E2; } }

.mea_services .icon, .mea_services a[target=_blank]:after,
.mea_services span,
.mea_services a,
.council-services .icon,
.council-services a[target=_blank]:after,
.council-services span,
.council-services a {
  display: block;
  clear: both; }

@media screen and (max-width: 768px) {
  #services.container {
    padding: 20px 0px; } }

@media screen and (min-width: 768px) {
  .council-services {
    margin-bottom: 40px; } }

.council-services .block {
  margin-bottom: 0px; }

.bd-category .council-services {
  padding-top: 20px; }

.council-services .col-4 {
  text-align: center;
  vertical-align: middle; }
  .council-services .col-4 .vh-align {
    width: 100%;
    height: 120px;
    text-align: center;
    display: table; }
    @media screen and (min-width: 768px) {
      .council-services .col-4 .vh-align {
        height: 240px; } }
  .council-services .col-4 .vh-item {
    display: table-cell;
    vertical-align: middle; }
    .council-services .col-4 .vh-item .subsections-links {
      font-family: "Museo Sans W01_300";
      text-decoration: underline;
      display: block;
      display: none; }
      @media screen and (min-width: 736px) {
        .council-services .col-4 .vh-item .subsections-links {
          display: block; } }

@media screen and (max-width: 736px) {
  .council-services {
    padding-left: 10px;
    padding-right: 10px; } }

.service-icon {
  display: block;
  width: 100%;
  margin: 0 auto; }
  .service-icon h3 {
    clear: both;
    display: block; }

.service-icon--icons {
  display: block;
  position: relative;
  height: 110px; }
  @media screen and (max-width: 736px) {
    .service-icon--icons {
      height: 70px; } }

.blue-icon,
.white-icon {
  position: relative;
  display: inline-block;
  transform: translateY(15%);
  -webkit-transform: translateY(15%);
  -moz-transform: translateY(15%); }
  @media screen and (max-width: 736px) {
    .blue-icon img,
    .white-icon img {
      max-width: 70%; } }

.white-icon {
  display: none; }

.services-links {
  display: inline-block;
  vertical-align: top; }

@media screen and (max-width: 736px) {
  .sec-title.landing-page {
    margin-bottom: 30px !important; } }

@media print {
  nav, .pullout-box, .homepage-header-2, .nav-main-wrap, footer, .news-and-events, form {
    display: none; }
  .alert {
    padding: 0;
    font-size: 1.6em; } }

.block {
  display: block;
  text-align: center;
  margin-bottom: 10px; }
  @media screen and (min-width: 768px) {
    .block {
      margin: 40px auto; } }

.slider {
  display: block;
  height: auto;
  width: 100%;
  position: relative; }
  @media screen and (min-width: 768px) {
    .slider {
      height: 600px; } }
  .slider .slider-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    position: relative;
    width: 100%;
    height: 315px;
    overflow: hidden; }
    .slider .slider-image img {
      max-width: 100%;
      height: auto; }
    @media screen and (min-width: 768px) {
      .slider .slider-image {
        height: 100%;
        max-height: 600px; } }
  .slider .content {
    background-color: rgba(0, 56, 108, 0.8);
    display: block;
    color: #fff;
    position: relative;
    width: auto;
    padding: 20px;
    padding-bottom: 60px;
    min-height: 400px; }
    @media screen and (min-width: 768px) {
      .slider .content {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 30px 100px 90px 30px; } }
    @media screen and (min-width: 960px) {
      .slider .content {
        padding: 30px 100px 90px 170px;
        bottom: 40px;
        min-height: auto; } }
    .slider .content p {
      font-size: 16px;
      margin-bottom: 0px; }
    .slider .content .txt--large {
      font-size: 24px;
      line-height: 1.2; }
      @media screen and (min-width: 768px) {
        .slider .content .txt--large {
          font-size: 30px; } }
  .slider .slider-copy {
    width: 100%;
    max-width: 715px; }

.txt {
  color: #FFFFFF;
  display: block;
  line-height: 1.1; }
  .txt--large {
    font-size: 30px;
    margin-bottom: 20px;
    font-family: "Museo Sans W01_500"; }
  .txt--medium {
    font-size: 20px; }

.link {
  line-height: 1.1;
  text-decoration: none; }
  .link--primary {
    font-size: 20px;
    color: #00386C; }
  .link--secondary {
    color: #007DC5; }
  .link:hover {
    text-decoration: underline; }

.owl-controls {
  color: #ffffff;
  width: 250px; }

.owl-prev,
.owl-next {
  background-color: #007DC5;
  font-family: "Museo Sans W01_700";
  font-size: 15px;
  font-size: 0.9375rem;
  padding: 0.5em;
  border-radius: 56px;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  text-align: center;
  -webkit-appearance: none;
  display: inline-block;
  width: 70px;
  text-transform: capitalize; }

.owl-next {
  float: right; }

.owl-dots {
  position: absolute;
  top: 10px;
  right: 125px; }
  .owl-dots .owl-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid #007DC5;
    border-radius: 50%;
    margin: 0 5px; }
    .owl-dots .owl-dot.active {
      background-color: #007DC5; }

.relative {
  position: relative; }

.home-slider .slider-copy a {
  color: #ffffff;
  text-decoration: none; }

.home-slider .owl-dots {
  top: auto;
  bottom: 34px;
  left: 107px;
  right: auto; }
  @media screen and (min-width: 768px) {
    .home-slider .owl-dots {
      bottom: 74px;
      left: 252px; } }

.home-slider-owl-controls {
  position: absolute;
  bottom: 30px;
  left: 20px;
  z-index: 1;
  color: #ffffff; }
  @media screen and (min-width: 960px) {
    .home-slider-owl-controls {
      bottom: 70px;
      left: 170px; } }

.homepage-news-events {
  margin: 0 auto;
  display: block;
  padding: 40px 0; }
  .homepage-news-events .h2 {
    margin-bottom: 20px; }
  .homepage-news-events .pullout-box p {
    margin-bottom: 0px; }
  .homepage-news-events .pullout-box li {
    margin-bottom: 25px; }
  .homepage-news-events .link {
    display: block;
    font-family: "Museo Sans W01_700";
    font-size: 20px;
    color: #00386C;
    line-height: 30px; }

.news-and-events {
  padding: 0px; }
  .news-and-events a {
    text-decoration: none; }
    .news-and-events a:hover {
      text-decoration: underline; }

.home-events {
  list-style: none;
  display: block; }
  .home-events a {
    font-family: "Museo Sans W01_500";
    font-size: 20px;
    color: #00386C;
    text-decoration: none; }
    .home-events a:hover {
      text-decoration: underline; }

.explore-the-borough {
  padding: 40px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  background-color: rgba(199, 223, 243, 0.23); }
  @media screen and (min-width: 850px) {
    .explore-the-borough {
      background-repeat: no-repeat;
      background-image: url(../images/Borough-Map.png);
      background-position: -400px 0px; } }
  @media screen and (min-width: 1050px) {
    .explore-the-borough {
      background-image: url(../images/Borough-Map.png);
      background-position: -112px 0px;
      padding: 50px 0;
      min-height: 730px; } }
  .explore-the-borough .copy {
    display: block;
    margin-bottom: 25px; }
    .explore-the-borough .copy p {
      margin-bottom: 20px;
      font-family: "Museo Sans W01_300";
      color: #00172D; }
      .explore-the-borough .copy p:nth-of-type(1) {
        font-family: "Museo Sans W01_500"; }
  .explore-the-borough .h2 {
    font-family: "Museo Sans W01_500";
    font-size: 24px;
    line-height: 1.1;
    color: #00386C;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: solid 1px #CCD8E2; }
    @media screen and (min-width: 768px) {
      .explore-the-borough .h2 {
        font-size: 30px;
        line-height: 1.1; } }
  .explore-the-borough .col-3 {
    float: none;
    max-width: 100%; }
    @media screen and (min-width: 850px) {
      .explore-the-borough .col-3 {
        max-width: 55%;
        float: right; } }
    @media screen and (min-width: 1050px) {
      .explore-the-borough .col-3 {
        max-width: 45%;
        padding: 0px 40px 0px 20px; } }

.float-right {
  float: none; }
  @media screen and (min-width: 768px) {
    .float-right {
      max-width: 55%;
      float: right; } }

.footer-tel,
.footer-email {
  color: #fff;
  display: block; }

.footer-tel {
  font-size: 30px; }

.footer-email {
  font-size: 16px;
  margin-bottom: 30px; }
  .footer-email:hover {
    text-decoration: none; }

.footer-nav {
  width: 100%;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    .footer-nav:first-of-type {
      margin-bottom: 0px; } }
  @media screen and (min-width: 768px) {
    .footer-nav {
      width: 50%;
      padding: 0 15px; } }
  .footer-nav li {
    border-bottom: solid 1px #406990;
    margin-bottom: 0px !important; }
  .footer-nav a {
    font-size: 14px;
    line-height: 1.1;
    display: block;
    padding: 15px 0; }

.footer-logo {
  background-image: url(../images/meawhite-logo.png);
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
  width: 150px;
  height: 65px;
  text-indent: -99999px;
  margin-bottom: 0px; }
  @media screen and (min-width: 990px) {
    .footer-logo {
      width: 200px;
      height: 75px;
      margin-bottom: 30px; } }

address {
  color: #fff;
  font-style: normal; }
  address span {
    display: block; }

.introduction {
  color: #00386C;
  font-size: 18px;
  line-height: 24px;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px; }
  @media screen and (min-width: 768px) {
    .introduction {
      font-size: 36px;
      line-height: 48px;
      padding-bottom: 40px; } }

.ch-header,
.search-form {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.search-form {
  display: none; }
  @media screen and (min-width: 880px) {
    .search-form {
      display: block;
      width: 370px;
      height: auto;
      float: right;
      margin-top: 15px; }
      .search-form .form-wrap {
        border: none;
        border-radius: 55px;
        text-align: left;
        position: relative;
        z-index: 99999; }
        .search-form .form-wrap input[type=text],
        .search-form .form-wrap input[type=search] {
          text-align: left;
          width: 100%;
          border-radius: 55px;
          height: 50px; }
        .search-form .form-wrap .btn, .search-form .form-wrap .page-content form input[type=submit], .page-content form .search-form .form-wrap input[type=submit] {
          background: #007DC5 url(../images/icons/Shape.svg) no-repeat;
          background-position: center center;
          border-radius: 100%;
          width: 50px;
          height: 50px;
          padding: 0px;
          position: absolute;
          right: 5px; }
        .search-form .form-wrap input[type=text],
        .search-form .form-wrap input[type=search],
        .search-form .form-wrap input[type=submit], .search-form .form-wrap .btn, .search-form .form-wrap .page-content form input[type=submit], .page-content form .search-form .form-wrap input[type=submit] {
          display: inline-block;
          vertical-align: middle; } }

#search-result-spec li {
  display: block;
  border-bottom: solid 1px #CCD8E2;
  padding-bottom: 30px;
  margin-bottom: 30px; }
  #search-result-spec li h4 {
    margin-bottom: 0;
    font-family: "Museo Sans W01_700"; }
  #search-result-spec li .ex-sml {
    font-size: 14px;
    color: #7F9CB6 !important;
    margin-bottom: 30px !important; }
  #search-result-spec li p {
    font-family: "Museo Sans W01_300";
    font-size: 16px;
    color: #333333;
    margin-bottom: 0px; }

.results {
  padding: 40px;
  margin-bottom: 50px;
  background-color: #F2F8FC;
  display: block; }
  .results p {
    color: #00386C;
    margin-bottom: 0px;
    font-family: "Museo Sans W01_500";
    font-size: 20px; }

.page-content h1, .page-content h2, .page-content h3 {
  color: #00386C; }

@media screen and (min-width: 768px) {
  .page-content h1 {
    margin-top: 12px; } }

@media screen and (max-width: 600px) {
  .page-content {
    padding-left: 0px;
    padding-right: 0px; } }

@media screen and (max-width: 768px) {
  .page-content {
    margin-bottom: 40px; } }

@media screen and (max-width: 600px) {
  .breadcrumb a,
  .breadcrumb .last {
    font-size: 14px; } }

@media screen and (max-width: 600px) {
  .category-header .container, .page-header .container {
    padding-top: 20px;
    padding-bottom: 20px; } }

@media screen and (max-width: 600px) {
  .latest-news-patch {
    padding: 20px 20px 0 20px; } }

@media screen and (min-width: 601px) {
  .latest-news-patch {
    margin-bottom: 40px; } }

.latest-news-patch .article {
  position: relative; }
  .latest-news-patch .article img {
    width: 100%;
    height: auto;
    display: block; }
  .latest-news-patch .article--large-img, .latest-news-patch .article--txt {
    display: block; }
    @media screen and (min-width: 768px) {
      .latest-news-patch .article--large-img, .latest-news-patch .article--txt {
        display: inline-block;
        vertical-align: top;
        width: 50%; } }
  .latest-news-patch .article--large-img {
    position: relative; }
    .latest-news-patch .article--large-img img {
      margin-bottom: 0px; }
    @media screen and (min-width: 1045px) {
      .latest-news-patch .article--large-img {
        width: 65%; } }
    .latest-news-patch .article--large-img.e-block {
      width: 100%;
      display: block; }
      .latest-news-patch .article--large-img.e-block img {
        margin-bottom: 0px; }
  @media screen and (min-width: 1045px) {
    .latest-news-patch .article--txt {
      width: 35%; } }
  .latest-news-patch .article--txt.e-block {
    width: 100%;
    display: block; }
    .latest-news-patch .article--txt.e-block .copy {
      padding: 20px; }
  .latest-news-patch .article--txt .copy {
    padding: 20px; }
    @media screen and (min-width: 768px) {
      .latest-news-patch .article--txt .copy {
        padding: 20px 40px; } }
    @media screen and (min-width: 1045px) {
      .latest-news-patch .article--txt .copy {
        padding: 50px 40px 50px 40px; } }

.latest-news-patch a {
  text-decoration: none;
  cursor: pointer; }
  .latest-news-patch a:hover {
    text-decoration: underline; }

.latest-news-patch .publication--status,
.latest-news-patch .publication--dates {
  color: #7F9CB6;
  margin-bottom: 30px; }

.latest-news-patch .grid-row {
  margin-left: 0px;
  margin-right: 0px; }
  .latest-news-patch .grid-row .col-3 {
    padding-left: 0px; }

.latest-news {
  background-color: #F2F8FC; }
  .latest-news.no-bg {
    background-color: transparent; }
    .latest-news.no-bg .copy {
      background-color: #F2F8FC; }
  .latest-news .owl-controls {
    position: relative;
    float: none;
    bottom: 20px;
    left: 20px; }
    @media screen and (min-width: 768px) {
      .latest-news .owl-controls {
        float: right;
        margin-right: 12%;
        margin-left: 0px;
        margin-top: -70px; } }
    @media screen and (min-width: 1045px) {
      .latest-news .owl-controls {
        margin-right: 12%; } }
  .latest-news .owl-dots {
    right: 77px; }
  .latest-news h4 {
    font-size: 24px; }

.list-recent-publications {
  margin-left: 0px !important;
  padding: 20px; }
  @media screen and (min-width: 601px) {
    .list-recent-publications {
      padding: 0px; } }
  .list-recent-publications .grid-row {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .list-recent-publications .grid-row .col-3 {
      padding-left: 0px; }
  .list-recent-publications a {
    text-decoration: none;
    cursor: pointer;
    color: #00386C; }
    .list-recent-publications a:hover {
      text-decoration: underline; }
  .list-recent-publications h2 {
    margin-bottom: 20px; }
  .list-recent-publications .tenders-heading,
  .list-recent-publications h4 {
    font-size: 20px;
    font-family: "Museo Sans W01_700"; }

.article {
  font-family: "Museo Sans W01_300";
  text-decoration: none; }
  .article img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px; }
  .article h4,
  .article .link {
    display: block;
    font-family: "Museo Sans W01_700";
    font-size: 20px;
    color: #00386C;
    line-height: 30px; }
  .article a {
    display: block;
    position: relative; }

.article-specific {
  font-family: "Museo Sans W01_300";
  padding: 30px 0px; }
  @media screen and (min-width: 1045px) {
    .article-specific {
      padding: 0px 20px; } }
  .article-specific .publication--status {
    margin-bottom: 30px; }
  .article-specific p {
    font-size: 16px; }
  .article-specific .news-summary p {
    font-family: "Museo Sans W01_300";
    font-size: 18px; }

.background--primary {
  background-color: #00386C; }

.background--secondary {
  background: #F2F8FC; }

.news-sidebar {
  position: relative;
  padding: 0 20px; }
  .news-sidebar h2,
  .news-sidebar article {
    border-bottom: solid 1px #CCD8E2;
    color: #00386C;
    font-size: 20px; }
  .news-sidebar h2 {
    padding-bottom: 25px;
    margin-bottom: 0px;
    font-size: 24px; }
  .news-sidebar article {
    padding: 25px 0px; }
    .news-sidebar article:last-of-type {
      margin-bottom: 30px; }
  .news-sidebar a {
    color: #00386C;
    text-decoration: none;
    cursor: pointer;
    font-family: "Museo Sans W01_700";
    margin-bottom: 10px;
    display: block;
    font-size: 20px; }
  .news-sidebar .publication--dates {
    display: block; }
  .news-sidebar .bttn {
    color: #7F9CB6;
    width: 200px;
    font-size: 16px;
    margin-bottom: 0px; }
    .news-sidebar .bttn:after {
      content: '';
      right: 25px; }
  .news-sidebar .block {
    display: block;
    margin: 0px auto; }
  .news-sidebar--inner {
    padding: 30px; }
  @media screen and (max-width: 1045px) {
    .news-sidebar {
      max-width: 100%; } }

.intro {
  font-size: 18px;
  border-top: solid 1px #CCD8E2;
  padding-top: 30px; }

.recently-added {
  border-bottom: solid 1px #CCD8E2;
  padding-bottom: 10px; }

@media screen and (max-width: 1045px) {
  .tenders {
    padding-top: 20px; } }

.tenders h2 {
  border-bottom: solid 1px #CCD8E2;
  padding-bottom: 10px; }

.tenders #publication-search {
  background-color: #F2F8FC;
  border: none;
  padding: 40px; }
  .tenders #publication-search input[type=text] {
    border-color: #CCD8E2; }
  .tenders #publication-search .tender-col {
    display: block;
    vertical-align: middle;
    max-width: 100%; }
    @media screen and (min-width: 768px) {
      .tenders #publication-search .tender-col {
        display: inline-block;
        vertical-align: middle;
        max-width: 20%; } }
    .tenders #publication-search .tender-col.input {
      width: 100%;
      margin-bottom: 20px; }
      @media screen and (min-width: 768px) {
        .tenders #publication-search .tender-col.input {
          padding-right: 20px;
          margin-bottom: 0px;
          max-width: 80%; } }

.tenders .list-recent-publications li {
  border-bottom: solid 1px #CCD8E2;
  margin-bottom: 30px; }

.tenders .publication--status {
  padding-right: 0px; }
  .tenders .publication--status:after {
    content: '|';
    display: inline-block;
    padding: 0 5px 0 10px; }

.tenders .publication--status,
.tenders .publication--dates {
  display: inline-block; }

.tenders .e-source {
  background-color: #F2F8FC;
  padding: 40px;
  margin-bottom: 30px; }
  .tenders .e-source p {
    margin-bottom: 0px; }
  .tenders .e-source .e-sourcing-heading {
    font-size: 18px;
    border-bottom: solid 1px #CCD8E2;
    display: block;
    color: #00386C;
    padding-bottom: 15px;
    margin-bottom: 15px; }

#publication-search {
  font-size: 18px;
  display: block;
  color: #00386C;
  background-color: #F2F8FC;
  border: none;
  padding: 40px;
  margin-bottom: 40px; }

#bin-search,
#penalty-payment,
#online_penalty_payment,
#bin-purchase-form {
  background-color: transparent;
  border: none; }
  #bin-search .styled-select select,
  #penalty-payment .styled-select select,
  #online_penalty_payment .styled-select select,
  #bin-purchase-form .styled-select select {
    background: transparent;
    width: 100%;
    max-width: 450px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 1.1;
    height: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: solid 1px #CCD8E2; }
  #bin-search h4,
  #penalty-payment h4,
  #online_penalty_payment h4,
  #bin-purchase-form h4 {
    border-bottom: solid 1px #CCD8E2;
    padding: 0 0 20px 0; }
  #bin-search .unit,
  #penalty-payment .unit,
  #online_penalty_payment .unit,
  #bin-purchase-form .unit {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 450px; }
  #bin-search input[type='text'],
  #penalty-payment input[type='text'],
  #online_penalty_payment input[type='text'],
  #bin-purchase-form input[type='text'] {
    border: solid 1px #CCD8E2; }
  #bin-search form,
  #penalty-payment form,
  #online_penalty_payment form,
  #bin-purchase-form form {
    background-color: transparent;
    border: none !important; }

.styled-select {
  position: relative;
  width: 100%;
  max-width: 450px; }
  .styled-select:after {
    content: '';
    background-image: url(../images/icons/select.svg);
    background-repeat: no-repeat;
    width: 9px;
    height: 21px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    right: 22px; }
  .styled-select select {
    background: transparent;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 1.1;
    height: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: solid 1px #CCD8E2;
    cursor: pointer;
    color: #7F9CB6; }

.community-intro {
  background-color: #F2F8FC;
  padding: 40px; }
  .community-intro h3 {
    font-family: "Museo Sans W01_500";
    font-size: 20px;
    margin: 0px; }
  .community-intro.margin-bottom {
    margin-bottom: 50px; }

.section-links .heading-min {
  margin: 0px; }
  .section-links .heading-min:hover {
    background-color: #F2F8FC; }

.section-links a {
  border-top: solid 1px #CCD8E2;
  padding: 15px 20px;
  width: 100%;
  display: block; }
  .section-links a:after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: url(../images/icons/pullout-box-right-arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -6px; }
  .section-links a:hover {
    text-decoration: underline; }

.sub-section-links {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px; }

.penalty-fees {
  background: #F2F8FC;
  border: solid 1px #CCD8E2;
  padding: 30px !important; }
  .penalty-fees .unit {
    width: 100%;
    max-width: 762px !important; }

@media screen and (min-width: 600px) {
  .col-1 {
    padding: 0 20px; } }

figure {
  background: #F2F8FC;
  margin-bottom: 30px; }
  figure img {
    width: 100%;
    height: 280px; }
  figure figcaption {
    padding: 40px;
    color: #00386C; }
    figure figcaption p {
      margin: 0px;
      font-size: 20px; }

.sub-sections {
  margin: 0px !important;
  padding: 0px;
  list-style: none;
  border-bottom: solid 1px #CCD8E2; }
  .sub-sections li a {
    border-top: solid 1px #CCD8E2;
    color: #00386C;
    padding: 15px 20px;
    width: 100%;
    display: block;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    font-size: 1em;
    font-family: "Museo Sans W01_500"; }
    .sub-sections li a:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: url(../images/icons/pullout-box-right-arrow.svg);
      background-repeat: no-repeat;
      position: absolute;
      right: 30px;
      top: 50%;
      margin-top: -6px; }
    .sub-sections li a:hover {
      background-color: #F2F8FC; }

iframe {
  width: 100%; }

.upcoming-events h2 {
  border-bottom: solid 1px #CCD8E2;
  padding-bottom: 20px; }

@media screen and (max-width: 1045px) {
  .upcoming-events {
    max-width: 100%;
    padding: 0px;
    padding-top: 30px; } }

.upcoming-events .pullout-box {
  padding-top: 0px; }

@media screen and (min-width: 1045px) {
  .upcoming-events--inner {
    padding-left: 40px; } }

.ca_hero_image {
  display: block;
  clear: both; }
  .ca_hero_image img {
    display: block;
    width: 100%; }

.ca-introduction {
  background-color: #F2F8FC;
  font-family: "Museo Sans W01_500";
  font-size: 18px;
  color: #00386C;
  line-height: 26px;
  padding: 20px;
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .ca-introduction {
      font-size: 20px;
      line-height: 30px;
      padding: 40px;
      margin-bottom: 40px; } }
  .ca-introduction p {
    font-family: "Museo Sans W01_500";
    font-size: 20px;
    line-height: 30px; }
    .ca-introduction p:last-of-type {
      margin-bottom: 0px; }

.gutter-images,
.copy--right {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-family: "Museo Sans W01_300";
  font-size: 14px;
  width: 100%; }
  @media screen and (min-width: 1045px) {
    .gutter-images,
    .copy--right {
      width: 50%; } }
  .gutter-images a,
  .copy--right a {
    color: #fff;
    text-decoration: none;
    font-family: "Museo Sans W01_300"; }
    .gutter-images a:hover,
    .copy--right a:hover {
      text-decoration: underline; }
  .gutter-images span,
  .copy--right span {
    margin: 0 10px; }
  .gutter-images .copyright-text,
  .copy--right .copyright-text {
    display: inline-block; }

.copy--right {
  text-align: left; }
  @media screen and (min-width: 1045px) {
    .copy--right {
      text-align: right; } }

.gutter-images {
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .gutter-images {
      margin-bottom: 0px; } }
  .gutter-images #__ba_panel {
    vertical-align: middle; }
  .gutter-images img {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    margin-bottom: 10px; }
    @media screen and (min-width: 768px) {
      .gutter-images img {
        margin-bottom: 0px; } }
    .gutter-images img.worldpay {
      margin-left: 5px; }

.owl-carousel {
  display: block;
  clear: both; }

.badge {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  padding: 10px 20px;
  color: #FFF;
  text-transform: uppercase; }
  .badge.sml-badge {
    font-size: 11px;
    padding: 5px 10px; }
  .badge.Green {
    background-color: #59b654; }
  .badge.Pink {
    background-color: #ffb6c1; }
  .badge.Red {
    background-color: #ff0000; }
  .badge.Blue {
    background-color: #063c76; }
  .badge.Orange {
    background-color: #f47900; }

.infinite-container {
  display: block;
  overflow: hidden;
  margin: 0 -20px; }

.infinite-item {
  display: block;
  width: 100%;
  float: left;
  padding: 0 20px; }
  @media screen and (min-width: 580px) {
    .infinite-item {
      width: 50%; } }
  @media screen and (min-width: 768px) {
    .infinite-item {
      width: 33.333%; } }

#__ba_panel {
  display: block;
  width: 120px;
  height: 52px;
  position: fixed;
  bottom: 10px;
  right: 0px;
  z-index: 99999999; }

.customPrevBtn,
.customNextBtn {
  color: #FFFFFF; }

.gw-launchpad-icon-button:focus {
  outline: 2px solid #007dc5; }

*:focus {
  outline-color: #00386c !important; }

.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }
